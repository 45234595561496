import React, { useEffect, useState } from "react";
import Loading from "../Loading";
import { Link } from "react-router-dom";
import EditEquipment from "./EditEquipment";

const defaultEdit = {
  name: "",
  title: "",
  icon: "",
  description: "",
  image: null,
  id_chambre: "",
};

function Equipment({ loading, equipments, handleEquipment, id_chambre }) {
  const [edit, setEdit] = useState(defaultEdit);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleEdit = (equipment) => setEdit(equipment);

  return (
    <div className="facilities">
      <EditEquipment
        equipment={edit}
        handleClose={handleClose}
        handleEquipment={handleEquipment}
        id_chambre={id_chambre}
        open={open}
      />
      <div className="mb-3 ">
        <strong className="d-block mb-3">Équipements</strong>
        {loading ? (
          <Loading color="var(--secondary)" />
        ) : equipments.length > 0 ? (
          equipments.map((equipment) => (
            <Link
              to={"#"}
              className="btn btn-secondary light"
              key={equipment.id}
              onClick={() => {
                handleEdit(equipment);
                handleOpen();
              }}
            >
              {equipment.icon ? (
                <i className={`${equipment.icon} me-2`}></i>
              ) : (
                <></>
              )}

              {equipment.name}
            </Link>
          ))
        ) : (
          <>Aucun</>
        )}
      </div>
    </div>
  );
}

export default Equipment;
