import React from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { getUsers } from "../../services/UserService";
import Roles from "../components/Accounts/Roles";
import BrowseAccount from "../components/Accounts/Browse";
import Addrole from "../components/Accounts/Addrole";
import Add from "../components/Accounts/Add";
import CheckPermission from "../../templates/components/CheckPermission/CheckPermission";

function Accounts() {
  const [users, setUsers] = React.useState([]);
  const [openRoles, setOpenRoles] = React.useState(false);
  const [openAddRoles, setOpenAddRoles] = React.useState(false);
  const [openAddUser, setOpenAddUser] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  const openRolesModal = () => setOpenRoles(true);
  const closeRolesModal = () => setOpenRoles(false);

  const openAddRolesModal = () => setOpenAddRoles(true);
  const closeAddRolesModal = () => setOpenAddRoles(false);

  const openAddUserModal = () => setOpenAddUser(true);
  const closeAddUserModal = () => setOpenAddUser(false);

  const handleUsers = () => {
    setLoading(true);
    getUsers()
      .then(({ data }) => {
        setUsers(data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    handleUsers();
  }, []);

  return (
    <div>
      <div
        className="d-flex align-items-center mb-4"
        style={{ justifyContent: "space-between" }}
      >
        <h2 className="mb-0">Comptes</h2>
        <CheckPermission permission="create_user">
          <Button variant="primary" className="me-2" onClick={openAddUserModal}>
            <i className="fa fa-user-plus me-2"></i>
            <span>Nouveau compte</span>
          </Button>
        </CheckPermission>
      </div>
      <Roles open={openRoles} closeModal={closeRolesModal} />
      <Addrole show={openAddRoles} handeClose={closeAddRolesModal} />
      <Add
        handeClose={closeAddUserModal}
        show={openAddUser}
        handleUsers={handleUsers}
      />
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Header
              className="d-flex align-items-center flex-wrap"
              style={{ justifyContent: "space-between" }}
            >
              <Card.Title>Liste des comptes utilisateurs</Card.Title>

              <div className="d-flex">
                <CheckPermission permission="create_role">
                  <Button
                    variant="outline-secondary"
                    className="me-2"
                    onClick={openAddRolesModal}
                  >
                    <i className="fa fa-cogs me-2"></i>
                    <span>Nouveau rôle</span>
                  </Button>
                </CheckPermission>
                <CheckPermission permission="browse_role">
                  <Button variant="secondary" onClick={openRolesModal}>
                    <i className="fa fa-user-shield me-2"></i>
                    <span>Rôles</span>
                  </Button>
                </CheckPermission>
              </div>
            </Card.Header>
            <CheckPermission permission="browse_user">
              <Card.Body>
                <BrowseAccount
                  users={users}
                  loading={loading}
                  handleUsers={handleUsers}
                />
              </Card.Body>
            </CheckPermission>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default Accounts;
