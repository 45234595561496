import equipement_chambre_name from '../../../images/documentation/chambres/equipement_chambre_name.png';
import equipement_fields from '../../../images/documentation/chambres/equipement_fields.png';
import equipement_detail from '../../../images/documentation/chambres/equipement_detail.png';


export const ChambreEquipementAjoutGuide = [
    {
        title: "Etape 1 : Aller à l'onglet Equipements",
        paragraph: "L'onglet se trouve dans le menu de navigation à gauche de l'écran.",
        illustration : null,
    },

    {
        title: "Etape 2 : Cliquer sur le nom de la chambre à laquelle vous voulez ajouter un équipement",
        paragraph: "",
        illustration : equipement_chambre_name,
    },
    {
        title: "Etape 3 : Cliquer sur le button d'option puis sur Ajouter un équipement",
        paragraph: "Le button d'option se trouve en haut à droite de la liste des équipements de la chambre.",
        illustration : equipement_detail,
    },
    {
        title: "Etape 4 : Remplir les informations sur l'équipement",
        paragraph: `Remplir les informations sur  dans le formulaire.

                    Les informations sur  sont :
                    - Nom
                    - titre
                    - Icone : C'est l'icone qui sera affiché sur la chambre, documentation : `+ process.env.REACT_APP_PEER_HOST+ '/documentation/chambre/icones' +`
                    - Description
                    - Photo


                    NB : Toutes les informations sont obligatoires sauf l'Icone et la Photo.

        `,
        illustration : equipement_fields,
    }
    ,
    {
        title: "Etape 4 : Cliquer sur le bouton Ajouter",
        paragraph: "Cliquer, puis attendre la confirmation de l'ajout de l'équipement.",
        illustration : null,
    }


]

