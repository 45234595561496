import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { articles } from "../../data/documentation";
import Search from "../components/Documentation/Search";
import Request from "../components/Documentation/Request";

function Documentation() {
  return (
    <div>
      <div className="row page-titles mx-0">
        <ol className="breadcrumb">
          <li className="breadcrumb-item active">
            <Link to={`/#`}>Documentation</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={`/#`}>Articles</Link>
          </li>
        </ol>
      </div>
      <Search />
      <div className="card w-100 py-5 px-4">
        <Container className="mt-0">
          <h2 className="mb-4">Articles</h2>
          <Row>
            {articles.map((article, index) => (
              <Col lg={4} md={6} sm={12} key={index}>
                <div className="mb-5">
                  <h5>{article.name}</h5>
                  <div className="d-flex flex-column">
                    {article.pages.map((page, j) => (
                      <Link to={page.url} className="py-2 w-100" key={j}>
                        {page.title}
                      </Link>
                    ))}
                    <Link to={article.url} className="py-2 text-primary">
                      <span className="me-3">Voir plus</span>
                      <i className="fa fa-arrow-right"></i>
                    </Link>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
          <Request />
        </Container>
      </div>
    </div>
  );
}

export default Documentation;
