import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import AddClient from "../../components/Clients/Add";
import { daydiff } from "../../../helpers/reservations";
import { Button, Card, Col, Row } from "react-bootstrap";
import { formatInputDateTime, formatInputDate } from "../../../helpers/dates";
import { createOrder } from "../../../services/OrderService";
import { getClientsAll } from "../../../services/ClientService";
import { getAllChambres } from "../../../services/ChambreService";

const defaultState = {
  room: null,
  day: 0,
  client: null,
  date_order: null,
  arrival: null,
  departure: null,
  status: 0,
  note: null,
};

const STATUS = [
  { value: 0, label: "En cours" },
  { value: 1, label: "Completé" },
];

const toastOptions = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
};

function Add(props) {
  const { location } = props;
  const [show, setShow] = useState(false);
  const [rooms, setRooms] = useState([]);
  const [clients, setClients] = useState([]);
  const [state, setState] = useState(defaultState);
  const query = new URLSearchParams(location.search);
  const [selectClient, setSelectClient] = useState(false);

  let history = useHistory();

  const handleClose = () => {
    setSelectClient(true);
    setShow(false);
  };

  const defaultDate = new Date();
  defaultDate.setDate(defaultDate.getDate() + 1);

  const resetState = () => {
    setState(defaultState);
  };

  const handleField = (name, value) => {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleRooms = () => {
    getAllChambres().then(({ data }) => {
      setRooms(data);
    });
  };

  const handleClients = () => {
    getClientsAll().then(({ data }) => {
      setClients(data);
    });
  };

  const sendData = (e) => {
    e.preventDefault();
    
    const data = {
      chambre_id: state.room,
      client_id: state.client,
      date_reservation: state.date_order.split("T")[0],
      date_arrivee: state.arrival + ' 12:00:00',
      date_depart: state.departure + ' 12:00:00',
      nombre_jours: state.day,
      note: state.note,
    }
    

    createOrder(
      data
    )
      .then((response) => {
        if(response.status === 202 ){
          resetState();
          toast.success(`La reservation a été enregistré`, toastOptions);
          history.push("/orders");
        }
        else {
          toast.error(response.data.message, toastOptions)
        }
      })
      .catch((err) =>{
        toast.error(`Erreur pendant l'enregistrement `, toastOptions)
    });


  };

  const handleDefaultDate = () => {
    setState((prevState) => ({
      ...prevState,
      arrival: formatInputDateTime(defaultDate),
    }));
  };

  const handleDefaultRoom = (defaultRoom) => {
    if (query.size > 0 && query.get("room")) {
      setState((prevState) => ({
        ...prevState,
        room: parseInt(query.get("room")),
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        room: defaultRoom,
      }));
    }
  };

  const handleDefaultClient = (defaultClient) => {
    if (query.size > 0 && query.get("client")) {
      setState((prevState) => ({
        ...prevState,
        client: parseInt(query.get("client")),
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        client: defaultClient,
      }));
    }
  };

  useEffect(() => {
    handleRooms();
    handleClients();
    handleDefaultDate();
  }, []);

  useEffect(() => {
    if (selectClient) {
      handleClients();
      setSelectClient(false);
    }
  }, [selectClient]);

  useEffect(() => {
    if (rooms.length && state.room === null) {
      handleDefaultRoom(rooms[0].id);
    }
  }, [rooms]);

  useEffect(() => {
    if (clients.length) {
      handleDefaultClient(clients[0].id);
    }
  }, [clients]);

  useEffect(() => {
    if (state.day && state.arrival) {
      const new_date = new Date(state.arrival);
      new_date.setDate(new_date.getDate() + parseInt(state.day));
      setState((prevState) => ({
        ...prevState,
        departure: formatInputDate(new_date),
      }));

      console.log(formatInputDateTime(new_date));
    }
  }, [state.day]);

  useEffect(() => {
    if (state.departure && state.arrival) {
      setState((prevState) => ({
        ...prevState,
        day: daydiff(state.arrival, state.departure),
      }));
    }
  }, [state.departure]);

  return (
    <div>
      <Card>
        <Card.Body>
          <h4 className="mb-4">Nouvelle reservation</h4>
          <form className="d-block" onSubmit={sendData}>
            <Row>
              <Col md={6} sm={12}>
                <div className="form-group mb-4">
                  <label>Chambre</label>
                  <select
                    value={state.room}
                    name="room"
                    className="form-control w-100"
                    onChange={(e) => handleField("room", e.target.value)}
                    required
                  >
                    {rooms.map((item, index) => (
                      <option value={item.id} key={index}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
              </Col>
              <Col md={6} sm={12}>
                <div className="form-group mb-4">
                  <label>Nombre de jours / nuits</label>
                  <input
                    type="number"
                    name="jours"
                    className="form-control"
                    onChange={(e) => handleField("day", e.target.value)}
                    value={state.day}
                    required
                  />
                </div>
              </Col>
              <Col md={6} sm={12}>
                <Row
                  style={{
                    alignItems: "center",
                  }}
                >
                  <Col md={10}>
                    <label>Client</label>
                    <select
                      value={state.client}
                      name="client"
                      className="form-control w-100"
                      onChange={(e) => handleField("client", e.target.value)}
                      required
                    >
                      {clients.map((item, index) => (
                        <option value={item.id} key={index}>
                          {`${item.lastname} ${item.firstname}`}
                        </option>
                      ))}
                    </select>
                  </Col>
                  <Col
                    md={2}
                    style={{
                      alignContent: "center",
                      justifyContent: "center",
                      marginTop: "20px",
                    }}
                  >
                    <Button
                      variant="primary"
                      onClick={() => {
                        setShow(true);
                      }}
                    >
                      <i className="fa fa-plus"></i>
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col md={6} sm={12}>
                <div className="form-group mb-4">
                  <label>Date de réservation</label>
                  <input
                    type="datetime-local"
                    name="date_order"
                    className="form-control"
                    onChange={(e) => handleField("date_order", e.target.value)}
                    value={state.date_order}
                    required
                  />
                </div>
              </Col>
              <Col md={6} sm={12}>
                <div className="form-group mb-4">
                  <label>Date d’arrivée (à partir de 12H:00)</label>
                  <input
                    type="date"
                    name="arrival"
                    className="form-control"
                    onChange={(e) => handleField("arrival", e.target.value)}
                    defaultValue={state.arrival}
                    value={state.arrival}
                    required
                  />
                </div>
              </Col>
              <Col md={6} sm={12}>
                <div className="form-group mb-4">
                  <label>Date départ (avant 12H:00)</label>
                  <input
                    type="date"
                    name="departure"
                    className="form-control"
                    onChange={(e) => handleField("departure", e.target.value)}
                    value={state.departure}
                    required
                  />
                  
                </div>
              </Col>
              <Col sm={12}>
                <div className="form-group mb-4">
                  <label>Status</label>
                  <select
                    value={state.status}
                    name="status"
                    className="form-control w-100"
                    onChange={(e) => handleField("status", e.target.value)}
                    required
                  >
                    {STATUS.map((item, index) => (
                      <option value={item.value} key={index}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </Col>
              <Col sm={12}>
                <div className="form-group mb-5">
                  <label>Notes</label>
                  <textarea
                    className="form-control"
                    rows="6"
                    name="note"
                    onChange={(e) => handleField("note", e.target.value)}
                  ></textarea>
                </div>
              </Col>
            </Row>
            <Button variant="secondary" type="submit" className="w-100">
              <i className="fa fa-check me-3"></i>
              <span>Enregistrer</span>
            </Button>
          </form>
        </Card.Body>
        {show && <AddClient open={show} handleClose={handleClose} />}
      </Card>
    </div>
  );
}

export default Add;
