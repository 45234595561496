import React, { useState } from "react";
import { ReservationActions } from "./Actions";
import { Dropdown } from "react-bootstrap";

import Loading from "../Loading";
import { asset_img } from "../../../helpers/data";
import { formatDate, formatTime } from "../../../helpers/dates";
import { formatPrices } from "../../../helpers/numbers";

function OrderHistory({ datas, loading }) {
  const [selectBtn, setSelectBtn] = useState("Nouveau");

  return (
    <div className="card">
      <div className="card-header border-0">
        <h4 className="fs-20">Historique de reservations</h4>
        <div className="newest ms-3">
          <Dropdown>
            <Dropdown.Toggle
              as="div"
              className=" btn-select-drop default-select btn i-false"
            >
              {selectBtn} <i className="fas fa-angle-down ms-2 "></i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => setSelectBtn("Ancien")}>
                Ancien
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setSelectBtn("Nouveau")}>
                Nouveau
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <div className="card-body pt-0">
        {loading ? (
          <Loading color="var(--secondary)" />
        ) : (
          datas?.map((item, index) => (
            <div className="row align-items-center mb-3" key={index}>
              <div className="col-xl-4 col-sm-7">
                <div className="purchase-history d-flex align-items-center">
                  <img src={asset_img(item.chambre_image)} alt="" />
                  <div className="ms-3">
                    <h4 className="guest-text font-w500">{item.chambre_name}</h4>
                    <span className="fs-14 d-block mb-2 text-secondary">
                      #{item.chambre_ref}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-sm-5 col-6">
                <div className="ms-0 ms-sm-2">
                  <span className="d-block">Arrivé</span>
                  <span className="text-black fs-18 font-w500">
                    { formatDate(item.date_arrivee) }
                  </span>
                  <span className="fs-14 d-block">{ formatTime(item.date_arrivee) }</span>
                </div>
              </div>
              <div className="col-xl-2 col-sm-4 col-6">
                <div>
                  <span className="d-block">Départ</span>
                  <span className="text-black fs-18 font-w500">
                  { formatDate(item.date_depart) }
                  </span>
                  <span className="fs-14 d-block">{ formatTime(item.date_arrivee) }</span>
                </div>
              </div>
              <div className="col-xl-2 col-sm-4 col-6">
                <div className="mt-sm-0 mt-3">
                  <span className="d-block mb-2 text-black">Prix</span>
                  <span className="font-w500 fs-18 text-black">
                    { formatPrices(item.chambre_prix) } Ar
                    <small className="fs-14 ms-2 text-secondary">/nuit</small>
                  </span>
                </div>
              </div>
              <div className="col-xl-2 col-sm-4 col-6">
                <div className="d-flex align-items-center mt-sm-0 mt-3">
                  {/* <Link to={"#"} className="btn btn-secondary light">
                    Voir les notes
                  </Link> */}
                  <ReservationActions id={item.id} />
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
}

export default OrderHistory;
