import { Link } from "react-router-dom";
import React, { useState } from "react";
import { Button, Nav } from "react-bootstrap";
import CheckPermission from "../../../templates/components/CheckPermission/CheckPermission";

const TABS = [{ label: "Tout", value: null }];

function Header() {
  const [status, setStatus] = useState(null);

  return (
    <div className="d-flex justify-content-between align-items-center flex-wrap">
      <div className="card-action coin-tabs mb-2">
        <Nav as="ul" className="nav nav-tabs">
          {TABS.map((tab, index) => (
            <Nav.Item as="li" className="nav-item" key={index}>
              <Nav.Link
                className={`nav-link ${status === tab.value ? "active" : ""}`}
                onClick={() => setStatus(tab.value)}
              >
                {tab.label}
              </Nav.Link>
            </Nav.Item>
          ))}
        </Nav>
      </div>
      <div className="d-flex align-items-center mb-2 flex-wrap">
      <CheckPermission permission="create_facture">
        <Link to="/invoices/create">
          <Button className="ms-3" variant="secondary">
            <i
              className="fa fa-solide fa-plus"
              style={{
                marginRight: 5,
              }}
            ></i>
            Nouvelle facture
          </Button>
        </Link>
        </CheckPermission>
      </div>
    </div>
  );
}

export default Header;
