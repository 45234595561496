import React, { useState, useRef, useEffect } from "react";
import "bootstrap-daterangepicker/daterangepicker.css";
import { getClients, getClientsCount } from "../../services/ClientService";
import { getPaginationData } from "../../services/PaginationService";
import List from "../components/Clients/List";
import Header from "../components/Clients/Header";
import Add from "../components/Clients/Add";
import Edit from "../components/Clients/Edit";

const Client = () => {
  const [datas, setDatas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [edit, setEdit] = useState(false);
  const [clients_counts, setClientsCounts] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const sort = 10;

  const handleOpenAdd = () => setOpenAdd(true);
  const handleCloseAdd = () => setOpenAdd(false);

  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);

  const handleEdit = (client) => {
    setEdit(client);
    handleOpenEdit();
  };

  const handleData = () => {
    setLoading(true);
    getPaginationData("client", 1)
      .then(({ data }) => {
          setDatas(data);
          setCurrentPage(1);
      }
    )
      .finally(() => setLoading(false));
  };


  const paginate = (page_number) => {
    setLoading(true);
    getPaginationData("client", page_number)
      .then(({ data }) => {
          setDatas(data);
          setCurrentPage(page_number);
      }
    )
    .finally(() => setLoading(false));
  };
  
  // use effect
  useEffect(() => {
    handleData();
  }, []);

  useEffect(() => {
    // getting response
    getClientsCount()
      .then((response) => {
        setClientsCounts(response.data.count);
      }
    );
    
  }, []);


  // chackbox
  const chackbox = document.querySelectorAll(".sorting_1 input");
  const motherChackBox = document.querySelector(".sorting_asc input");
  const chackboxFun = (type) => {
    for (let i = 0; i < chackbox.length; i++) {
      const element = chackbox[i];
      if (type === "all") {
        if (motherChackBox.checked) {
          element.checked = true;
        } else {
          element.checked = false;
        }
      } else {
        if (!element.checked) {
          motherChackBox.checked = false;
          break;
        } else {
          motherChackBox.checked = true;
        }
      }
    }
  };

  return (
    <>
      <Header handleData={handleData} handleOpen={handleOpenAdd} />
      <Add
        handleClose={handleCloseAdd}
        open={openAdd}
        handleData={handleData}
      />
      <Edit
        client={edit}
        handleClose={handleCloseEdit}
        handleData={handleData}
        open={openEdit}
      />
      <div className="row mt-4">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body p-0">
              <List
                chackboxFun={chackboxFun}
                data={datas}
                loading={loading}
                sort={sort}
                handleEdit={handleEdit}
                clients_counts={clients_counts}
                paginate={paginate}
                currentPage={currentPage}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Client;
