import ajoutClientStep2 from '../../../images/documentation/clients/add_client_1.png';
import ajoutClientStep3 from '../../../images/documentation/clients/add_client_2.png';

export const ClientAjoutGuide = [
    {
        title: "Etape 1 : Aller à l'onglet Clients",
        paragraph: "L'onglet Clients se trouve dans le menu de navigation à gauche de l'écran.",
        illustration : null,
    },

    {
        title: "Etape 2 : Cliquer sur le bouton plus (+) pour ajouter un nouveau client",
        paragraph: "Le bouton plus (+) se trouve en haut à droite de la page.",
        illustration : ajoutClientStep2,
    },
    {
        title: "Etape 3 : Remplir les informations du client",
        paragraph: `Remplir les informations du client dans le formulaire.

                    Les informations du client sont :
                        nom, prenom, date de naisssance, lieu de naissance, adresse,
                        profession, email, numéro de téléphone, nationalité, nature pièce d'identité (passeport ou CIN ou permis),
                        numéro pièce d'identité, date de délivrance pièce d'identité, venant de, allant de, Date d'expiration‌ et la photo.

                    NB : Toutes les informations sont obligatoires sauf la photo.

        `,
        illustration : ajoutClientStep3,
    }
    ,
    {
        title: "Etape 4 : Cliquer sur le bouton Ajouter",
        paragraph: "Le bouton Ajouter se trouve en bas à droite du formulaire. Une fois cliqué, le client est ajouté à la liste des clients.",
        illustration : null,
    }


]

