import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { Button, Modal } from "react-bootstrap";
import { editClient } from "../../../services/ClientService";
import { asset_img } from "../../../helpers/data";
import { validateForm } from "../../../helpers/client";
import { formatInputDate } from "../../../helpers/dates";

const defaultState = {
  firstname: "",
  lastname: "",
  birth_date: "",
  birth_place: "",
  profession: "",
  email: "",
  adresse: "",
  telephone: "",
  photo: null,
  nationality: "",
  nature_pid: "Passeport",
  numero_pid: "",
  delivery_date_pid: "",
  from_pid: "",
  to_pid: "",
  date_expiration_pid: "",
};

const ToastOption = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
};

function Edit({ client, open, handleClose, handleData }) {
  const [state, setState] = React.useState(defaultState);
  const [loading, setLoading] = React.useState(false);

  const notifyError = (message) => toast.error(message, ToastOption);

  const handleState = (name, value) =>
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));

  const handleFile = (e) => {
    setState((prevState) => ({
      ...prevState,
      photo: e.target.files[0],
    }));
  };

  const save = () => {
    if (validateForm(state)) {
      setLoading(true);
      editClient(client.id, state)
        .then(() => {
          toast.success(`Client modifié`, ToastOption);
          setState(defaultState);
          handleData();
          handleClose();
        })
        .catch((err) => {
          notifyError(`Erreur pendant l'enregistrement`);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      notifyError(`Veuillez remplir les champs obligatoires`);
    }
  };

  useEffect(() => {
    setState(client);
  }, [client]);

  return (
    <Modal
      show={open}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Modifier</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 className="mb-4">Entrez les informations sur le client</h4>
        <div>
          <div className="form-group mb-3">
            <label>Nom</label>
            <input
              type="text"
              className="form-control input-default mb-2"
              placeholder={"Nom"}
              value={state.lastname}
              onChange={(e) => handleState("lastname", e.target.value)}
            />
          </div>
          <div className="form-group mb-3">
            <label>Prénom</label>
            <input
              type="text"
              className="form-control input-default mb-2"
              placeholder={"Prénom"}
              value={state.firstname}
              onChange={(e) => handleState("firstname", e.target.value)}
            />
          </div>
          <div className="form-group mb-3">
            <label>Date de naissance</label>
            <input
              type="date"
              className="form-control input-default mb-2"
              placeholder={"Date de naissance"}
              value={state.birth_date}
              onChange={(e) => handleState("birth_date", e.target.value)}
            />
          </div>
          <div className="form-group mb-3">
            <label>Lieu de naissance</label>
            <input
              type="text"
              className="form-control input-default mb-2"
              placeholder={"Lieu de naissance"}
              value={state.birth_place}
              onChange={(e) => handleState("birth_place", e.target.value)}
            />
          </div>
          <div className="form-group mb-3">
            <label>Profession</label>
            <input
              type="text"
              className="form-control input-default mb-2"
              placeholder={"Profession"}
              value={state.profession}
              onChange={(e) => handleState("profession", e.target.value)}
            />
          </div>
          
          <div className="form-group mb-3">
            <label>Email</label>
            <input
              type="email"
              className="form-control input-default mb-2"
              placeholder={"Email"}
              value={state.email}
              onChange={(e) => handleState("email", e.target.value)}
            />
          </div>
          <div className="form-group mb-3">
            <label>Adresse</label>
            <input
              type="text"
              className="form-control input-default mb-2"
              placeholder={"Adresse"}
              value={state.adresse}
              onChange={(e) => handleState("adresse", e.target.value)}
            />
          </div>
          <div className="form-group mb-3">
            <label>Téléphone</label>
            <input
              type="text"
              className="form-control input-default mb-2"
              placeholder={"Téléphone"}
              value={state.telephone}
              onChange={(e) => handleState("telephone", e.target.value)}
            />
          </div>
          <div className="form-group mb-3">
            <label>Nationalité</label>
            <input
              type="text"
              className="form-control input-default mb-2"
              placeholder={"Nationalité"}
              value={state.nationality}
              onChange={(e) => handleState("nationality", e.target.value)}
            />
          </div>
          <div className="form-group mb-3">
            <label>Nature pièce d'identité</label>
            <select
              defaultValue={state.nature_pid ?? "Passeport"}
              className="form-control wide"
              onChange={(e) => handleState("nature_pid", e.target.value)}
            >
              <option value="Passeport">Passeport</option>
              <option value="CIN">CIN</option>
              <option value="Permis">Permis</option>
            </select>
          </div>
          <div className="form-group mb-3">
            <label>Numéro pièce d'identité</label>
            <input
              type="text"
              className="form-control input-default mb-2"
              placeholder={"Numéro pièce d'identité"}
              value={state.numero_pid}
              onChange={(e) => handleState("numero_pid", e.target.value)}
            />
          </div>
          <div className="form-group mb-3">
            <label>Date de délivrance pièce d'identité</label>
            <input
              type="date"
              className="form-control input-default mb-2"
              placeholder={"Date de délivrance pièce d'identité"}
              value={state.delivery_date_pid}
              onChange={(e) => handleState("delivrance_date_pid", e.target.value)}
            />
          </div>
          <div className="form-group mb-3">
            <label>Venant de :</label>
            <input
              type="text"
              className="form-control input-default mb-2"
              placeholder={"Venant de "}
              value={state.from_pid}
              onChange={(e) => handleState("from_pid", e.target.value)}
            />
          </div>
          <div className="form-group mb-3">
            <label>Allant de :</label>
            <input
              type="text"
              className="form-control input-default mb-2"
              placeholder={"Allant de "}
              value={state.to_pid}
              onChange={(e) => handleState("to_pid", e.target.value)}
            />
          </div>
          <div className="form-group mb-3">
            <label>Date d'expiration‌</label>
            <input
              type="date"
              className="form-control input-default mb-2"
              value={formatInputDate(state.date_expiration_pid)}
              onChange={(e) =>
                handleState("date_expiration_pid", e.target.value)
              }
            />
          </div>
          <div className="mb-3">
            <label htmlFor="formFile" className="form-label">
              Choisir une photo
            </label>
            <input
              className="form-control"
              type="file"
              accept="image/*"
              onChange={handleFile}
            />
            {client.photo ? (
              <div className="mt-3">
                <label className="form-label d-block">Photo actuelle</label>
                <img height={150} src={asset_img(client.photo)} alt="" />
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-dark" onClick={handleClose}>
          Fermer
        </Button>
        <Button variant="secondary" onClick={save} disabled={loading}>
          Modifier
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default Edit;
