import React, {useState, useEffect} from "react";
import { useParams, useHistory } from "react-router-dom";
import { users, history as historyWifi, monthlyData, onlineUsers } from "../../../../data/wifi";
import HistoryWifiUser from "./historyUser";
import StatistiquesUser from "./statistiquesUser";
import ModifyWifiAccount from "./modifyUser";
import { formatDateTime } from "../../../../helpers/dates";
import { getWifiUser, deleteWifiUser, activateWifiUser, desactivateWifiUser } from "../../../../services/wifiService";
import { notifyError, notifySuccess } from "../../../../helpers/notification";
import Loading from "../../../components/Loading";
import qrcode from '../../../../images/wifi/qrcode.jpg';

const status_list = [
{value : 'desactivé', text : 'Désactivé', color : 'danger'},
{value : 'activé', text : 'Activé', color : 'success'},
{value : 'expiré', text : 'Expiré', color : 'danger'},
{value : 'en attente', text : 'En attente', color : 'warning'}
]

function ShowWifiUser ()
{
    const params_id = parseInt(useParams().id);
    const [user, setUser] = useState({});
    const [loading, setLoading] = useState(false);
    const [history, setHistory] = useState([]);
    const [monthly, setMonthly] = useState([]);
    const [online, setOnline] = useState([]);
    const [openModify, setOpenModify] = useState(false);
    const pageHistory = useHistory()
    const handleOpenModify = () => setOpenModify(true);
    const handleCloseModify = () => setOpenModify(false);
    const handleUser = () => {
        getWifiUser(params_id)
        .then( (response) => {  
            setUser(response.data.user);
        })
    }

    const handleUserData = (name, value) =>
        setUser((user) => ({
        ...user,
        [name]: value,  
    }));


    const handleHistory = () => {
        setHistory(historyWifi);
    }

    const handleMonthly = () => {
        const monthly = monthlyData;
        setMonthly(monthly);
    }

    const handleOnline = () => {
        const online = onlineUsers.filter(o => o.user_id === params_id);
        setOnline(online);
    }



    const activation = () => {
        if(user.status === 1){
            if(window.confirm('Voulez vous vraiment désactiver ce compte ?')){
                setLoading(true)
                desactivateWifiUser(user.id)
                    .then((response) => {
                        if(response.status === 202 ){
                            setUser(response.data.user);
                            handleUserData('status', 0);
                            setLoading(false)
                        }
                        else {
                            notifyError(response.data.message)
                        }
                    })
                    .catch((err) =>{
                        notifyError(`Erreur pendant la désactivation ${err}`)
                    })
                    .finally( setLoading(false));
            }
        }
        if(user.status === 0){
            if(window.confirm('Voulez vous vraiment activer ce compte ?')){
                setLoading(true)
                activateWifiUser(user.id)
                    .then((response) => {
                        if(response.status === 202 ){
                            setUser(response.data.user);
                            handleUserData('status', 1);
                            setLoading(false)
                        }
                        else {
                            notifyError(response.data.message)
                        }
                    })
                    .catch((err) =>{
                        notifyError(`Erreur pendant l'activation`)
                    })
                    .finally( setLoading(false));
                    
            }
        }
                
    } 

    const supprimer = () => {
        if(window.confirm('Voulez vous vraiment supprimer ce compte ?')){
          setLoading(true)
          deleteWifiUser(user.id)
            .then((response) => {
              if(response.status === 202 ){
                notifySuccess(`Le compte utilisateur a été supprimé`);
                pageHistory.push('/wifi')
              }
              else {
                notifyError(response.data.message)
              }
            })
            .catch((err) =>{
              notifyError(`Erreur pendant l'enregistrement `)
          })
          .finally( setLoading(false));
        }
    } 

    const datetimenow = () => {
        const date = new Date();
        return date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate()+' '+date.getHours()+':'+date.getMinutes()+':'+date.getSeconds()
    }


    useEffect(() => {
        handleUser();
        handleHistory();
        handleMonthly();
        handleOnline();
    }, []);

    return(
        <>
            <ModifyWifiAccount
                handleClose={handleCloseModify}
                open={openModify}
                user = { user }
                handleUserData = { handleUserData }
            />


            <div className="row h-50">
                { user.id === undefined ? <Loading color="var(--secondary)" /> :
                    (
                    <>
                        <div className="col-5 mb-1">
                            <div className="row h-100 justify-content-center align-items-center">
                                <b>
                                    Identifiant : { user?.id } 
                                </b> 
                            </div>                 
                        </div>
                        <div className="col-7 mt-1">
                            <div className="row justify-content-end align-items-center">
                                <img src={ qrcode} alt={`qrcode-${user.firstname+ '-'+user.lastname}`} 
                                    style={{ width : '150px', height : '100px'}}
                                />
                            </div>
                        </div>
                    </>
                    )
                }
            </div>

            <div className="row mb-3">
                <div className="col-5 mt-1" style={{ transform: 'scale(0.95)'}}>
                    {
                        user.id === undefined ? <Loading color="var(--secondary)" /> : (
                            <div className={`row rounded text-white bg-${status_list[user?.status]?.color}`} >
                                <div className="col-2 d-flex align-items-center flex-column justify-content-center">
                                    <i className="fa fa-user fa-2x"></i>
                                </div>
                                <div className="col-10 d-flex flex-column  pt-2 pb-2">
                                    <h5 className="fw-bold">{ user?.firstname+' '+user?.lastname }</h5>
                                    <span className="">{ user?.localisation }</span>
                                    <span className="mt-1">{ user?.contact }</span>
                                    <span className="mt-3">Compte {status_list[user?.status]?.value} (IP : {user?.ip })</span>
                                </div>
                            </div>
                        )
                    }
                    
                </div>
                <div className="col-5 mt-1" style={{ transform: 'scale(0.95)'}}>
                        {
                            user.id === undefined ? <Loading color="var(--secondary)" /> : (
                                <div className={`row rounded shadow`} >
                                    <div className="col-2 d-flex flex-column align-items-center  justify-content-center">
                                        <i className="fa fa-calendar fa-2x"></i>
                                    </div>
                                    <div className="col-10 d-flex flex-column  pt-2 pb-2">
                                        <span className="mt-1"> <b>Compte créé le </b>{ formatDateTime(user?.created_at) }</span>
                                        <span className="mt-1"> <b>Activé le </b>{ formatDateTime(user?.date_activation) }</span>
                                        <span className="mt-1"> <b>Expiré le </b>{ formatDateTime(user?.date_expiration) }</span>
                                        <span className="mt-3"> <b>Dernière connexion : </b>{ formatDateTime(user?.date_last_connection)   }</span>
                                    </div>
                                </div>
                            )

                        }
                        
                </div>

                <div className="col-2 d-flex flex-column " style={{ transform: 'scale(0.95)'}}>
                    <button className="btn btn-secondary" onClick={ handleOpenModify }> Modifier </button>
                    {

                        user.status === 0 ?
                        // if date_expiration > datetime now and date_activation < datetime now
                        new Date(user.date_expiration) > new Date(datetimenow()) && new Date(user.date_activation) < new Date(datetimenow()) ?
                        (
                            <button className="btn bg-success text-white mt-1" onClick={ activation} disabled = { loading }> Activer </button>
                        ) 
                        :
                        null 
                        : user.status === 1 ?  (
                            <button className="btn bg-danger text-white mt-1" onClick={ activation} disabled = { loading }> Désactiver </button>
                        ) : 
                        null 
                    } 

                    <button className="btn bg-danger text-white mt-1" onClick={ supprimer } disabled = {loading}> Supprimer </button>
                </div>
                
            </div>

            <StatistiquesUser />

            <div className='mt-5 mb-1 row'>
                <div className='col-8 d-flex flex-row align-items-center'>
                    <i className="fas fa-history"></i>             
                    <span className=""> Historique d'utilisation</span>
                </div>
                <div className='col-2'>
                    <select className='form-control'>
                        <option value={'todayHistory'}>Aujourd'hui</option>
                        <option value={'monthHistory'}>Ce mois ci</option>
                    </select>
                </div>
                <div className='col-2'>
                    <input 
                        type='date'
                        className='form-control'
                    />

                </div>
            </div>
            
            <HistoryWifiUser
                history={history}
                loading={loading}
            /> 

        </>
    )
}

export default ShowWifiUser;
