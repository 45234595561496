import { createFormData } from "../helpers/services";
import axiosInstance from "./AxiosInstance";

export function getOrders(params) {
  return axiosInstance.get(`reservation/browse`, {
    params,
  });
}

export function getReservationCount() {
  return axiosInstance.get(`reservation/browse/count`);
}


export function getOrder(id) {
  return axiosInstance.get(`reservation/show/${id}`);
}

export function getDetailOrder(id) {
  return axiosInstance.get(`reservation/get/${id}`);
}

export function createOrder(postData) {
  return axiosInstance.post(`reservation/store`, createFormData(postData));
}

export function editOrder(postData, id) {
  return axiosInstance.post(`reservation/update/${id}`, createFormData(postData));
}

export function litigeOrder(id) {
  return axiosInstance.post(`reservation/set/status/litige/${id}`)
}

export function annulerOrder(id) {
  return axiosInstance.post(`reservation/set/status/annuler/${id}`)
}

export function payerOrder(id) {
  return axiosInstance.post(`reservation/set/status/paye/${id}`)
}

export function clotureOrder(id) {
  return axiosInstance.post(`reservation/set/status/cloture/${id}`)
}

export function archiveOrder(id) {
  return axiosInstance.patch(`reservation/archive/${id}`)
}

export function getReservationEncoursByClientId(clientId) {
  return axiosInstance.get(`reservation/get-reservation-by-client-id/${clientId}`);
}

export function getReservationPayeByClientId(clientId) {
  return axiosInstance.get(`reservation/get-reservation-paye-by-client-id/${clientId}`);
}