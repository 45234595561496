import React from "react";
import { Button, Modal } from "react-bootstrap";
import { editUser, getRoles } from "../../../services/UserService";
import { validateForm } from "../../../helpers/users";
import { toast } from "react-toastify";

const defaultState = {
  name: "",
  email: "",
  role: null,
};

const ToastOption = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
};

function Edit({ show, handeClose, user, handleUsers }) {
  const [state, setState] = React.useState(user ?? defaultState);
  const [roles, setRoles] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const notifyError = (message) => toast.error(message, ToastOption);

  const handleRoles = () =>
    getRoles().then(({ data }) => {
      setRoles(data);
    });

  const handleState = (name, value) =>
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));

  const save = () => {
    if (validateForm(state)) {
      setLoading(true);
      editUser(user, state)
        .then(() => {
          toast.success(`Compte modifié`, ToastOption);
          handleUsers();
          handeClose();
        })
        .catch((err) => {
          notifyError(`Erreur pendant la modification`);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      notifyError(`Veuillez remplir tous les champs`);
    }
  };

  React.useEffect(() => {
    handleRoles();
  }, [show]);

  React.useEffect(() => {
    if (user) {
      setState({
        ...user,
        role: user.role ?? roles.length > 0 ? roles[0].id : null,
      });
    }
  }, [user]);

  return (
    <Modal
      show={show}
      onHide={handeClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Modifier un compte
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 class="mb-4">Entrez les informations sur le compte</h4>
        <div>
          <div className="form-group mb-3">
            <label>Nom (complet)</label>
            <input
              type="text"
              className="form-control input-default mb-2"
              placeholder="Nom"
              value={state.name}
              onChange={(e) => handleState("name", e.target.value)}
            />
          </div>
          <div className="form-group mb-3">
            <label>Email</label>
            <input
              type="email"
              className="form-control input-default mb-2"
              placeholder="Email"
              value={state.email}
              onChange={(e) => handleState("email", e.target.value)}
            />
          </div>
          <div className="form-group mb-3">
            <label>Rôle</label>
            <select
              value={state.role}
              className="form-control"
              onChange={(e) => handleState("role", e.target.value)}
            >
              {roles.map((role, index) => (
                <option value={role.id} key={index}>
                  {role.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-dark" onClick={handeClose}>
          Fermer
        </Button>
        <Button variant="primary" onClick={save} disabled={loading}>
          Modifier
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default Edit;
