import chambre_add_button from '../../../images/documentation/chambres/chambre_add_button.png';
import chambre_forms  from '../../../images/documentation/chambres/chambre_forms.png';
import chambre_detail from '../../../images/documentation/chambres/chambre_detail.png';


export const ChambreAjoutGuide = [
    {
        title: "Etape 1 : Aller à l'onglet Chambres",
        paragraph: "L'onglet Chambres se trouve dans le menu de navigation à gauche de l'écran.",
        illustration : null,
    },

    {
        title: "Etape 2 : Cliquer sur le bouton plus Ajouter une chambre",
        paragraph: "Le bouton plus Ajouter une chambre se trouve en haut à droite de la page.",
        illustration : chambre_add_button,
    },
    {
        title: "Etape 3 : Remplir les informations de la chambre",
        paragraph: `Remplir les informations de la chambre dans le formulaire.

                    Les informations de la chambre sont :
                    - Le nom de la chambre
                    - Le prix de la chambre
                    - le type de lit
                    - l'étage de la chambre
                    - La description de la chambre
                    - La photo de la chambre


                    NB : Toutes les informations sont obligatoires sauf la photo et la description.

        `,
        illustration : chambre_forms,
    }
    ,
    {
        title: "Etape 4 : Cliquer sur le bouton Ajouter",
        paragraph: "Le bouton Ajouter se trouve en bas à droite du formulaire. Une fois cliqué, le client est ajouté à la liste des clients.",
        illustration : null,
    },
    {
        title: "Etape 5 : Vérifier que la chambre a été ajoutée",
        paragraph: `La chambre ajoutée doit être visible dans la liste des chambres.
                    Vous pouvez les modifier, réserver, supprimer ou voir les détails de la chambre en appuyant
                    sur le button de la chambre correspondante.
        `,
        illustration : chambre_detail,
    }


]

