import React from "react";
import ReactApexChart from "react-apexcharts";
import {getStatRemplissageMonthly, getStatRemplissageYearly } from "../../../services/dashbordService";

class ChartLineStatRempYear extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			series: [
				{
					name: 'Rempli',
					data: [0, 0, 0, 0, 0, 0],
					//radius: 12,	
				} 
				
			],
			options: {
				chart: {
					type: 'line',
					//height: 350,
					toolbar: {
						show: false,
					},
				},
				plotOptions: {
					line: {
						curve: "smooth",
						width: "10",

						//columnWidth: '35%',
						//endingShape: "rounded",
						//borderRadius: 5,
					},
				},
				// colors:['var(--primary)', 'var(--secondary)'],
				colors:['var(--secondary)'],
				dataLabels: {
				  enabled: false,
				},
				markers: {
					size: 6,  // Taille des marqueurs sur les points de données
					strokeWidth: 0,  // Épaisseur du contour des marqueurs
					hover: {
					size: 8,  // Taille des marqueurs lorsqu'ils sont survolés
					},
				},
				legend: {
					show: false,
					fontSize: '12px',
					labels: {
						colors: '#000000',
						
						},
					markers: {
						width: 18,
						height: 18,
						strokeWidth: 0,
						strokeColor: '#fff',
						fillColors: undefined,
						radius: 12,	
					}
				},
				stroke: {
					show: true,
					width: 2,
				},
				grid: {
					borderColor: '#eee',
				},
				xaxis: {
					categories: ['...', '...', '...', '...', '...', '...'],
					labels: {
						style: {
						   colors: '#787878',
						   fontSize: '13px',
						   fontFamily: 'poppins',
						   fontWeight: 100,
						   cssClass: 'apexcharts-xaxis-label',
						},
					},
					crosshairs: {
						show: false,
					}
				},
				yaxis: {
					labels: {
						style: {
							colors: '#787878',
							fontSize: '13px',
							fontFamily: 'poppins',
							fontWeight: 100,
							cssClass: 'apexcharts-xaxis-label',
						},
					},
				},
				fill: {
					opacity: 1
				},
				tooltip: {
					y: {
						formatter: function (val) {
							return  val + " %"
						}
					}
				}
			},
		};
	}

	componentDidMount() {
		getStatRemplissageYearly().then((response) => {
			// console.log(response);
			const data = response.data;
				this.setState({
					series: [
						{
							name: 'Taue de remplissage',
							data: data.rempli,
							//radius: 12,	
						} 
					],

					options: {
						...this.state.options,
						xaxis: {
							...this.state.options.xaxis,
							categories: data.label,
						}
					}
				});
		});
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps !== this.props) {
			getStatRemplissageYearly().then((response) => {
				// console.log(response);
				const data = response.data;
				this.setState({
					series: [
						{
							name: 'Taue de remplissage',
							data: data.rempli,
							//radius: 12,	
						} 
					],

					options: {
						...this.state.options,
						xaxis: {
							...this.state.options.xaxis,
							categories: data.label,
						}
					}
				});
			});
		}
	}

	render() {
		return (
			<div id="chart" >
				<ReactApexChart
				  options={this.state.options}
				  series={this.state.series}
				  type="line"
				  height={350} 
				/>
			</div>
		);
	}
}

export default ChartLineStatRempYear;