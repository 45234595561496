import Users from "./pages/Users";
import Clients from "./pages/Clients";
import Chambres from "./pages/Chambres";
import Checkout from "./pages/Checkout";
import Accounts from "./pages/Accounts";
import DetailsClient from "./pages/Client/Details";
import DetailChambre from "./pages/Chambre/Detail";

// Documentation
import Orders from "./pages/Orders";
import Add from "./pages/Orders/Add";
import Edit from "./pages/Orders/Edit";
import Details from "./pages/factures";
import Factures from "./pages/Facture";
import Error404 from "./pages/Error404";
import Error403 from "./pages/Error403";
import Error400 from "./pages/Error400";
import Error500 from "./pages/Error500";
import Error503 from "./pages/Error503";
import LockScreen from "./pages/LockScreen";
import Registration from "./pages/Registration";
import ForgotPassword from "./pages/ForgotPassword";
import AddAnotherAchat from "./components/factures/New_achat";
import FactureGenerate from "./components/factures/facture_generate";
import ShowCheckout from "./components/Checkout/Show";
import Add2Checkout from "./components/Checkout/Add2";
import OnlineClient from "./pages/Client/Online";
import { documentationRoutes } from "./components/Documentation/documentationRoutes";

// wifi
import Wifi from "./pages/wifi";
import ShowWifiUser from "./pages/wifi/user/show";
import ChenkingWifi from "./pages/wifi/ChenkingWifi";
import Dashboard from "./pages/DashBoard";
import Add2Checkin from "./components/Checkout/ChekinAdd";
import Calendar from "./pages/Calendar";

export const routes = [
  // Documentation
  ...documentationRoutes,

  // Dashboard
  { url: "", component: Dashboard },

  // Utilisateurs
  { url: "users", component: Users },

  // Factures
  { url: "invoices", component: Factures },
  { url: "invoices/create", component: Details },
  { url: "invoices/create/:id", component: Details },
  { url: "invoices/generate/:id_facture", component: FactureGenerate },
  { url: "invoices/addAnotherAchat/:id_facture", component: AddAnotherAchat },

  // Clients
  { url: "clients", component: Clients },
  { url: "client/online", component: OnlineClient },
  { url: "client/:id", component: DetailsClient },

  // Checkout
  { url: "checkout", component: Checkout },
  { url: "checkout/create", component: Add2Checkout },
  { url: "checkin/create", component: Add2Checkin },
  { url: "checkout/show/:id", component: ShowCheckout},

  // Chambres
  { url: "rooms", component: Chambres },
  { url: "room/:id", component: DetailChambre },

  // Comptes
  { url: "accounts", component: Accounts },

  // Reservations
  { url: "orders", component: Orders },
  { url: "orders/create", component: Add },
  { url: "orders/edit/:id", component: Edit },

  { url: "calendar", component: Calendar},

  // // wifi
  // { url: "wifi", component: Wifi },
  // { url: "wifi/user/:id", component: ShowWifiUser },
  // { url: "page-wifi", component: ChenkingWifi },

  /// pages
  { url: "page-register", component: Registration },
  { url: "page-lock-screen", component: LockScreen },
  { url: "page-forgot-password", component: ForgotPassword },
  { url: "page-error-400", component: Error400 },
  { url: "page-error-403", component: Error403 },
  { url: "page-error-404", component: Error404 },
  { url: "page-error-500", component: Error500 },
  { url: "page-error-503", component: Error503 },


];
