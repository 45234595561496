import React,{useState} from 'react';
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";





const CalendarComponent = ({onChangeDate}) =>{
	const [value, onChange] = useState(new Date());
    
	const handleDateChange = (newValue) => {
		onChange(newValue); 
		onChangeDate(newValue); 
	};

	return(
		<>
			<div className="card-body pb-2 loadmore-content" id="BookingContent">
				<div className="text-center event-calender border-bottom booking-calender ">
					{/* <input type='text' className="form-control d-none " id='datetimepicker1' /> */}
					<Calendar
                     onChange={handleDateChange} 
                     value={value}  
                     className="mb-4"
                     //custom bouton next and previous
                        next2Label={null}
                        prev2Label={null}
                     />
				</div>	
			</div>
		</>
	)
}
export default CalendarComponent;