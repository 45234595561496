import { createFormData } from "../helpers/services";
import axiosInstance from "./AxiosInstance";

export function getClient() {
  return axiosInstance.get(`facture/browse`);
}

export function getListFacture() {
  return axiosInstance.get(`facture/liste`);
}

export function getFacturesCount() {
  return axiosInstance.get(`facture/browse/count`);
}

export function postFacture(data) {
  return axiosInstance.post(
    `facture/store`,
    createFormData({
      ...data
    })
  );
}

export function showFacture(id) {
  return axiosInstance.get(`facture/show/${id}`);
}

export function AddAnotherAchatService(facture_id, data) {
  return axiosInstance.post(
    `facture/element/add/${facture_id}`,
    createFormData(data)
  );
}

// *******
export function getAllCheckoutsNeedInvoices() {
  return axiosInstance.get("facture/create");
}

export function archiveFacture(facture_id) {
  return axiosInstance.patch(`facture/archive/${facture_id}`);
}

export function sendFacture(publication_date) {
  return axiosInstance.post(`facture/send/${publication_date}`);
}