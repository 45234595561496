import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { validationCheckoutSchema } from "./schema";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useDisabled } from "./action";
import { getClientsAll } from "../../../services/ClientService";
import { createCheckout } from "../../../services/CheckoutService";
import swal from "sweetalert";
import { toast } from "react-toastify";
import { getReservationPayeByClientId } from "../../../services/OrderService";


function Add2Checkout() {
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState("");
  const [reservations, setReservations] = useState([]);
  const [inputFields, setInputFields] = useState([
    { raison: "", prixTotal: 0 },
  ]);
  const [selectedReservation, setSelectedReservation] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState(0)
  const disabled = useDisabled(inputFields);

  const ToastOption = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
  };

  const handleAddFields = () => {
    const values = [...inputFields];
    values.push({ raison: "", prixTotal: 0 });
    setInputFields(values);
  };

  const handleRemoveFields = (index) => {
    const values = [...inputFields];
    let reservation_id = values[index].reservation_id;
    values.splice(index, 1);
    setInputFields(values);
    if (reservation_id) {
      let selected = selectedReservation.filter(item => item !== reservation_id);
      setSelectedReservation(selected);
    }
  };

  const handleChange = (index, event) => {
    const values = [...inputFields];
    if (event.target.name === "raison") {
      values[index].raison = event.target.value;
    } else if (event.target.name === "prixTotal") {
      values[index].prixTotal = event.target.value;
    }
    setInputFields(values);
  };

  const save = (e) => {
    e.preventDefault();
    setIsLoading(true);

    const data = {
      paniers: JSON.stringify(inputFields),
      client_id: selectedClient,
      type: "out"
    }

    createCheckout(data).then(res => {
      setIsLoading(false);
      // swal({
      //   title: "Succès",
      //   text: "Checkout ajoutée avec succès!! Vous voulez le voir?",
      //   icon: "success",
      //   buttons: {
      //     confirm: "Oui",
      //     cancel: "Non",
      //   },
      // }).then(() => {
      //   //window.location.href = `invoices/generate/${res.data.id}`;
      //   console.log(res.data)
      // });
      setIsLoading(false);
      toast.success(`Checkout ajoutée avec succès!!`, ToastOption);
      setInputFields([
        { raison: "", prixTotal: 0 },
      ])
      setTotal(0)
      setSelectedClient("")
    }).catch(err => {
      setIsLoading(false);
      toast.error(`Une erreur s'est produite : ${err.message}`, ToastOption);
    })
  }

  useEffect(() => {
    // verif if inputField is empty
    if (inputFields?.length == 0) {
      setInputFields([
        { raison: "", prixTotal: 0 },
      ])
    }
  }, [inputFields])

  useEffect(() => {
    let total = 0;
    inputFields.forEach((item) => {
      total += parseInt(item.prixTotal);
    });

    setTotal(total);
  }, [inputFields]);

  useEffect(() => {
    getClientsAll().then((res) => {
      setClients(res.data);
    });
  }, []);

  const handleAddFieldSuggestion = (raison, prixTotal, reservation_id) => {
    const values = [...inputFields];
    setInputFields([...values, { raison: raison, prixTotal: prixTotal, reservation_id: reservation_id }]);
    setSelectedReservation([...selectedReservation, reservation_id]);
  }

  return (
    <div className="d-flex">
      <Card
        style={{
          width: "80%",
          marginRight: "3rem",
        }}
      >
        {" "}
        <Form onSubmit={(e) => save(e)}>
          <Card.Body>
            <div className="mb-5">
              <h1 className="mb-3">Checkout pour</h1>
              <Col>
                <Form.Label className="fw-bold fs-5">Client</Form.Label>
                <Form.Select
                  style={{
                    width: "300px",
                    height: "50px",
                  }}
                  name="client_id"
                  required
                  onChange={(e) => {
                    let selected = e.target.value;
                    setSelectedClient(selected);
                    getReservationPayeByClientId(selected).then(res => {
                      setReservations(res.data.reservations);
                    }).catch(err => {
                      setReservations([]);
                    })
                  }}
                >
                  {" "}
                  {clients?.length && (
                    <>
                      <option value="">--Selectionner un client--</option>
                      {clients?.length ? (
                        clients?.map((item) => (
                          <option value={item.id}>{item.firstname} {item.lastname}</option>
                        ))
                      ) : (
                        <option value="">--Aucun client trouvé --</option>
                      )}
                    </>
                  )}
                </Form.Select>
              </Col>
            </div>
            <hr></hr>
            <p style={{ fontWeight: "bold", fontSize: "20px" }}>Eléments</p>

            {inputFields?.map((inputField, index) => {
              return (
                <Row
                  key={index}
                  style={{
                  }}
                >
                  <Col sm={7}>
                    <Form.Label
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      Raison
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Petit déjeuner"
                      name="raison"
                      required
                      defaultValue={inputField.raison}
                      onChange={(event) => {
                        handleChange(index, event);
                      }}
                    />
                  </Col>

                  <Col sm={3}>
                    <Form.Label
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      Prix total
                    </Form.Label>
                    <Form.Control
                      type="number"
                      name="prixTotal"
                      defaultValue={inputField.prixTotal}
                      onChange={(event) => {
                        handleChange(index, event);
                      }}
                    />
                  </Col>
                  <Col
                    style={{
                      marginTop: "30px",
                    }}
                  >
                    <Button
                      className=""
                      variant="danger"
                      disabled={inputFields.length === 1}
                      onClick={() => handleRemoveFields(index)}
                    >
                      <i class="fa fa-minus" aria-hidden="true"></i>
                    </Button>
                  </Col>
                </Row>
              );
            })}

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginRight: "20px",
                marginTop: "20px"
              }}
            >
              <p style={{ fontWeight: "bold", fontSize: "20px" }}></p>
              <Button
                className="ms-3"
                variant="secondary"
                onClick={handleAddFields}
                disabled={disabled}
              >
                <i
                  className="fa fa-plus"
                  style={{
                    marginRight: "10px",
                  }}
                ></i>
                Ajouter un autre checkout
              </Button>
            </div>

            <hr></hr>
            <p style={{ fontWeight: "bold", fontSize: "20px" }}>Suggestion(s)</p>

            {reservations?.map((reservation, index) => {
              return (
                <Row
                  key={index}
                  style={{
                  }}
                >
                  <Col sm={7}>
                    <Form.Label
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      Raison
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Reservation : 01/01/2021 - 01/01/2021 (chambre 305)"
                      name="raison"
                      disabled
                      required
                      defaultValue={reservation.label}
                      onChange={(event) => {
                        handleChange(index, event);
                      }}
                    />
                  </Col>

                  <Col sm={3}>
                    <Form.Label
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      Prix total
                    </Form.Label>
                    <Form.Control
                      type="number"
                      name="prixTotal"
                      defaultValue={reservation.reste}
                      onChange={(event) => {
                        handleChange(index, event);
                      }}
                      disabled
                    />
                  </Col>
                  <Col
                    style={{
                      marginTop: "30px",
                    }}
                  >
                    {selectedReservation.includes(reservation.id) ? '' : (
                      <Button
                        className=""
                        variant="dark"
                        // disabled={inputFields.length === 1}
                        onClick={() => handleAddFieldSuggestion(reservation.label, reservation.reste, reservation.id)}
                      >
                        <i class="fa fa-plus" aria-hidden="true"></i>
                      </Button>
                    )}
                  </Col>
                </Row>
              );
            })}

            <Button
              variant="success"
              style={{
                width: "100%",
                backgroundColor: "#116455",
                marginTop: "20px"
              }}
              type="submit"
              disabled={isLoading}
            >
              Enregistrer
            </Button>
          </Card.Body>
        </Form>
      </Card>
      <Card
        style={{
          width: "30%",
        }}
      >
        <Card.Body>
          <h1 className="mt-2 mb-5">Votre Panier</h1>
          {inputFields?.length > 0 ? (
            inputFields?.map((item) => (
              <>
                <div className="d-flex justify-content-between">
                  <p className="fs-20">{item.raison}</p>
                  <p className="fs-20">{item.prixTotal} Ar</p>
                </div>
                <hr></hr>{" "}
              </>
            ))
          ) : (
            <p className="fs-20">Panier vide</p>
          )}
          <Button className="w-100 ">
            <div className="d-flex justify-content-between">
              <p className="fs-20 fw-bold">Total</p>
              <p className="fs-20 fw-bold"> {total} Ar</p>
            </div>
          </Button>
        </Card.Body>
      </Card>
    </div>
  );
}

export default Add2Checkout;
