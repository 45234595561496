import { Card, Row, Col } from "react-bootstrap";

export default function CheckoutGenerate({ data }) {
  console.log(data)
  return (
    <Card>
      <Card.Body>
        <h1 className="mb-3">Checkout</h1>
        <Row className="d-flex  mb-5 ">
          {/* <Col className="fs-20 ">Facture <small>{data[0].}</small></Col> */}
          <Col className="fs-30 fw-bold">#{data?.checkouts?.length > 0 && data?.checkouts[0]?.reference}</Col>
        </Row>
        <h1 className="mb-3">Client</h1>
        <Row className="d-flex justify-content-between mb-5 ">
          <Col>
            <p className="fs-18 fw-bold ">Nom</p>
            <p className="fs-20 ">{data?.client?.fullname}</p>
          </Col>
          <Col>
            <p className="fs-18 fw-bold ">Référence client</p>
            <p className="fs-20 ">{data?.client?.reference}</p>
          </Col>
        </Row>
        <Row className="d-flex justify-content-between mb-5 ">
          <Col>
            <p className="fs-18 fw-bold ">CVV</p>
            <p className="fs-20 ">{data.cvv}</p>
          </Col>
          <Col>
            <p className="fs-18 fw-bold ">Total payé</p>
            <p className="fs-20 ">{data.total} Ar</p>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}
