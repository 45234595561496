import React from 'react';
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import {Tab, Nav} from 'react-bootstrap';
import ChartBarStatRevenueDaily from './ChartBarStatRevenueDaily';
import ChartBarStatRevenueHebd from './ChartBarStatRevenueHebd';
import ChartLineStatRevenueMonthly from './ChartLineStatRevenueMonthly';
import ChartLineStatRevenueYearly from './ChartLineStatRevenueYearly';



const ReservationStats = ()=> {
	return(
		<>
			<Tab.Container defaultActiveKey="Daily">
				<div className="card">
					<div className="card-header border-0 flex-wrap">
						<h4 className="fs-20">Chiffre d'affaire</h4>
						<div className="card-action coin-tabs">
							<Nav as="ul" className="nav nav-tabs" >
								<Nav.Item as="li" className="nav-item">
									<Nav.Link eventKey="Daily" >Journalier</Nav.Link>
								</Nav.Item>
								<Nav.Item as="li" className="nav-item">
									<Nav.Link eventKey="Weekly">Hebdomadaire</Nav.Link>
								</Nav.Item>
								<Nav.Item as="li" className="nav-item">
									<Nav.Link className="nav-link" eventKey="Monthly">Mois</Nav.Link>
								</Nav.Item>
								<Nav.Item as="li" className="nav-item">
									<Nav.Link className="nav-link" eventKey="Yearly">Année</Nav.Link>
								</Nav.Item>
							</Nav>
						</div>
					</div>
					<div className="card-body pb-0">

						<Tab.Content>							
							<Tab.Pane className="tab-pane" eventKey="Daily">
								<div id="chartBar" className="chartBar">
									<ChartBarStatRevenueDaily />
								</div>
							</Tab.Pane>
							<Tab.Pane className="tab-pane" eventKey="Weekly">
								<div id="chartBar1" className="chartBar">
									<ChartBarStatRevenueHebd />	
								</div>
							</Tab.Pane>
							<Tab.Pane className="tab-pane" eventKey="Monthly">
								<div id="chartBar2" className="chartBar">
									<ChartLineStatRevenueMonthly />
								</div>
							</Tab.Pane>
							<Tab.Pane className="tab-pane" eventKey="Yearly">
								<div id="chartBar2" className="chartBar">
									<ChartLineStatRevenueYearly />
								</div>
							</Tab.Pane>
						</Tab.Content>		
					</div>
				</div>
			</Tab.Container>
		</>
	)
}
export default ReservationStats;