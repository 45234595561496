export function createFormData(data) {
  const formData = new FormData();
  for (const key in data) {
    if (Object.hasOwnProperty.call(data, key)) {
      formData.append(key, data[key] ?? "");
    }
  }

  return formData;
}
