import React from "react";
import { Button, Modal } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { validateForm } from "../../../helpers/chambres";
import { createChambre } from "../../../services/ChambreService";

const defaultState = {
  name: "",
  price: "",
  bed: "",
  floor: "",
  description: "",
  image: null,
};

const ToastOption = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
};

const fields = [
  { label: "Nom de la chambre*", key: "name", type: "text" },
  { label: "Prix*", key: "price", type: "number" },
  { label: "Type de lit*", key: "bed", type: "text" },
  { label: "Étage", key: "floor", type: "text" },
];

function AddChambre() {
  const [state, setState] = React.useState(defaultState);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const history = useHistory();

  const notifyError = (message) => toast.error(message, ToastOption);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleState = (name, value) =>
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));

  const handleFile = (e) => {
    setState((prevState) => ({
      ...prevState,
      image: e.target.files[0],
    }));
  };

  const save = () => {
    if(validateForm(state)){
      setLoading(true);
      createChambre(state).then(({data}) => {
        toast.success(`Chambre enregistré`, ToastOption);
        setState(defaultState);
        if(data.id){
          history.push(`/room/${data.id}`)
        }
      }).catch((err) => {
        notifyError(`Erreur pendant l'enregistrement`)
      }).finally(() => {
        setLoading(false)
      });
    } else{
      notifyError(`Veuillez remplir les champs obligatoires`)
    }
  };

  return (
    <>
      <Link to={"#"} className="btn btn-secondary" onClick={handleOpen}>
        + Ajouter une chambre
      </Link>
      <Modal
        show={open}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Ajouter une chambre
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className="mb-4">Entrez les informations sur la chambre</h4>
          <div>
            {fields.map((field, index) => (
              <div className="form-group mb-3" key={index}>
                <label>{field.label}</label>
                <input
                  type={field.type}
                  className="form-control input-default mb-2"
                  placeholder={field.label}
                  value={state[field.key]}
                  onChange={(e) => handleState(field.key, e.target.value)}
                />
              </div>
            ))}
            <div className="mb-3">
              <label htmlFor="formFile" className="form-label">
                Choisir une image
              </label>
              <input
                className="form-control"
                type="file"
                accept="image/*"
                onChange={handleFile}
              />
            </div>
            <div className="form-group mb-3">
              <label>Description</label>
              <textarea
                className="form-control"
                rows="4"
                onChange={(e) => handleState("description", e.target.value)}
                defaultValue={state.description}
              ></textarea>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-dark" onClick={handleClose}>
            Fermer
          </Button>
          <Button variant="primary" onClick={save} disabled={loading}>
            Ajouter
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddChambre;
