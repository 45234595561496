import React, { useEffect } from "react";
import { editChambre } from "../../../services/ChambreService";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { validateForm } from "../../../helpers/chambres";
import { Button, Modal } from "react-bootstrap";
import { asset_img } from "../../../helpers/data";

const defaultState = {
  name: "",
  price: "",
  bed: "",
  floor: "",
  description: "",
  image: null,
};

const ToastOption = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
};

function Edit({ open, chambre, handleClose, handleData }) {
  const [state, setState] = React.useState(chambre ?? defaultState);
  const [loading, setLoading] = React.useState(false);
  const history = useHistory();

  const notifyError = (message) => toast.error(message, ToastOption);

  const handleState = (name, value) =>
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));

  const handleFile = (e) => {
    setState((prevState) => ({
      ...prevState,
      image: e.target.files[0],
    }));
  };

  const save = () => {
    if (validateForm(state)) {
      setLoading(true);
      editChambre(chambre.id, state)
        .then(() => {
          toast.success(`Chambre enregistré`, ToastOption);
          setState(defaultState);
          history.push(`/room/${chambre.id}`);
          handleClose();

          if (handleData) handleData();
        })
        .catch((err) => {
          notifyError(`Erreur pendant la modification`);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      notifyError(`Veuillez remplir les champs obligatoires`);
    }
  };

  useEffect(() => {
    setState(chambre);
  }, [chambre]);

  return (
    <Modal
      show={open}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Modifier une chambre
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 className="mb-4">Remplissez les informations sur la chambre</h4>
        <div>
          <div className="form-group mb-3">
            <label>Nom de la chambre*</label>
            <input
              type="text"
              className="form-control input-default mb-2"
              placeholder="Nom de la chambre"
              value={state.name}
              onChange={(e) => handleState("name", e.target.value)}
            />
          </div>
          <div className="form-group mb-3">
            <label>Prix (par nuit)*</label>
            <input
              type="number"
              className="form-control input-default mb-2"
              placeholder="Prix"
              value={state.price}
              onChange={(e) => handleState("price", e.target.value)}
            />
          </div>
          <div className="form-group mb-3">
            <label>Type de lit*</label>
            <input
              type="text"
              className="form-control input-default mb-2"
              placeholder="Type de lit"
              value={state.bed}
              onChange={(e) => handleState("bed", e.target.value)}
            />
          </div>
          <div className="form-group mb-3">
            <label>Étage</label>
            <input
              type="text"
              className="form-control input-default mb-2"
              placeholder="Étage"
              value={state.floor}
              onChange={(e) => handleState("floor", e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="formFile" className="form-label">
              Choisir une image
            </label>
            <input
              className="form-control"
              type="file"
              accept="image/*"
              onChange={handleFile}
            />
            {chambre.image && typeof chambre.image === "string" ? (
              <div className="mt-4">
                <label className="form-label">Image actuelle</label>
                <img
                  src={asset_img(chambre.image)}
                  className="d-block"
                  height={150}
                  alt=""
                />
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="form-group mb-3">
            <label>Description</label>
            <textarea
              className="form-control"
              rows="4"
              onChange={(e) => handleState("description", e.target.value)}
              defaultValue={state.description}
              value={state.description}
            ></textarea>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-dark" onClick={handleClose}>
          Fermer
        </Button>
        <Button variant="primary" onClick={save} disabled={loading}>
          Modifier
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default Edit;
