import React from "react";
import { Link } from "react-router-dom";
import Actions from "./Actions";
import Loading from "../Loading";
import { formatPrices } from "../../../helpers/numbers";
import { asset_img } from "../../../helpers/data";
import Edit from "./Edit";

import default_img from "../../../images/defaults/907.png";

const defaultState = {
  name: "",
  price: "",
  bed: "",
  floor: "",
  description: "",
  image: null,
};

function List({ datas, loading }) {
  const [edit, setEdit] = React.useState(defaultState);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleEdit = (chambre) => setEdit(chambre);

  return (
    <>
      <Edit chambre={edit} handleClose={handleClose} open={open} />
      <table className="table card-table display mb-4 dataTablesCard booking-table room-list-tbl dataTable no-footer">
        <thead>
          <tr role="row">
            <th>Chambre</th>
            <th>Lit</th>
            <th>Étage</th>
            <th>Équipements</th>
            <th>Prix</th>
            <th>Status</th>
            <th className="bg-none"></th>
          </tr>
        </thead>
        <tbody>
          {!loading ? (
            datas && datas.length > 0 ? (
              datas.map((chambre, index) => (
                <tr
                  role="row"
                  className={index % 2 === 0 ? "odd" : "even"}
                  key={index}
                >
                  <td>
                    <div className="room-list-bx d-flex align-items-center">
                      <img
                        className="me-3 rounded"
                        src={
                          chambre.image ? asset_img(chambre.image) : default_img
                        }
                        alt=""
                      />
                      <div>
                        <span className=" text-secondary fs-14 d-block">
                          #{chambre.ref}
                        </span>
                        <Link
                          to={`/room/${chambre.id}`}
                          className=" fs-16 font-w500 text-nowrap"
                        >
                          {chambre.name}
                        </Link>
                      </div>
                    </div>
                  </td>
                  <td className="">
                    <span className="fs-16 font-w500 text-nowrap">
                      {chambre.bed}
                    </span>
                  </td>
                  <td>
                    <div>
                      <span className="fs-16 font-w500">{chambre.floor}</span>
                    </div>
                  </td>
                  <td className={chambre.equipements.length ? "facility" : ""}>
                    <div>
                      <span className="fs-16 comments">
                        {chambre.equipements?.map((equipement, j) => (
                          <span key={j}>
                            {equipement.name}
                            {j < chambre.equipements.length - 1 ? "," : ""}{" "}
                          </span>
                        ))}
                        {chambre.equipements.length === 0 ? (
                          <span className="text-muted fs-16 font-w500 text-nowrap">
                            Aucun
                          </span>
                        ) : (
                          <></>
                        )}
                      </span>
                    </div>
                  </td>
                  <td>
                    <div className="">
                      <span className="font-w500">
                        {formatPrices(chambre.price)} Ar
                        <small className="fs-14 ms-2">/ nuit</small>
                      </span>
                    </div>
                  </td>
                  <td>
                    <Link
                      to={"#"}
                      className={`btn btn-${
                        chambre.status === "non disponible"
                          ? "danger"
                          : "success"
                      } btn-md`}
                    >
                      {chambre.status === "non disponible"
                        ? "RESERVÉE"
                        : "DISPONIBLE"}{" "}
                    </Link>
                  </td>
                  <td>
                    <Actions
                      chambre={chambre}
                      handleEdit={handleEdit}
                      handleOpen={handleOpen}
                    />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="text-center  fs-20">
                  Aucune chambre trouvée
                  <br />
                </td>
              </tr>
            )
          ) : (
            <tr>
              <td colSpan="6" className="text-center  fs-20">
                <Loading color="var(--secondary)" />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
}

export default List;
