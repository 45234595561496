import React from 'react'
import { toast } from 'react-toastify';
import { assignPermission, retirePermission } from '../../../services/UserService';
import { DictionnairePermission } from './DictionnairePermission';

const Permission = ({ role, permission, active, handleRoles }) => {
  const notify = (message) => toast.success(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
  });

  const handlePermission = (e) => {
    const target = e.target;
    const data = { role: role.name, permission: permission.name }
    if (target.checked) {
      assignPermission(data).then(() => {
        handleRoles()
        notify(`${ DictionnairePermission[permission.name] } ajouté à ${role.name} !`);
      });
    }
    else {
      retirePermission(data).then(() => {
        handleRoles();
        notify(`${ DictionnairePermission[permission.name] } retiré de ${role.name} !`);
      });
    }
  };

  return (
    <div className="form-check custom-checkbox mb-3">
      <input
        type="checkbox"
        className="form-check-input"
        id={`permission-${permission.id}${role.id}`}
        checked={active}
        onChange={handlePermission}
      />
      <label
        className="form-check-label"
        htmlFor={`permission-${permission.id}${role.id}`}
      >
        { DictionnairePermission[permission.name] }
      </label>
    </div>
  );
};

export default Permission