import React, { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { editEquipment } from "../../../services/ChambreService";
import { validateEquipmentForm } from "../../../helpers/chambres";
import { asset_img } from "../../../helpers/data";
import DeleteEquipment from "./DeleteEquipment";

const defaultState = {
  name: "",
  title: "",
  icon: "",
  description: "",
  image: null,
  id_chambre: "",
};

const ToastOption = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
};

function EditEquipment({
  open,
  handleClose,
  id_chambre,
  handleEquipment,
  equipment,
}) {
  const [state, setState] = React.useState(
    equipment ?? {
      ...defaultState,
      id_chambre,
    }
  );
  const [loading, setLoading] = React.useState(false);

  const notifyError = (message) => toast.error(message, ToastOption);

  const handleState = (name, value) =>
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));

  const handleFile = (e) => {
    setState((prevState) => ({
      ...prevState,
      image: e.target.files[0],
    }));
  };

  const save = () => {
    if (validateEquipmentForm(state)) {
      setLoading(true);
      editEquipment(equipment.id, state)
        .then(({ data }) => {
          toast.success(`Équipement modifié`, ToastOption);
          setState({
            ...defaultState,
            id_chambre,
          });
          handleEquipment();
          handleClose();
        })
        .catch((err) => {
          notifyError(`Erreur pendant la modification`);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      notifyError(`Veuillez remplir les champs obligatoires`);
    }
  };

  useEffect(() => {
    setState(equipment)
  }, [equipment])

  return (
    <Modal
      show={open}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Modifier {equipment.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h4 className="mb-0">Remplissez le formulaire</h4>
          <DeleteEquipment equipment={equipment} handleEquipment={handleEquipment} handleClose={handleClose} />
        </div>
        <div>
          <div className="form-group mb-3">
            <label>Nom*</label>
            <input
              type="text"
              className="form-control input-default mb-2"
              placeholder="Nom"
              value={state.name}
              onChange={(e) => handleState("name", e.target.value)}
            />
          </div>
          <div className="form-group mb-3">
            <label>Titre*</label>
            <input
              type="text"
              className="form-control input-default mb-2"
              placeholder="Titre"
              value={state.title}
              onChange={(e) => handleState("title", e.target.value)}
            />
          </div>
          <div className="form-group mb-3">
            <label>Icone</label>
            <input
              type="text"
              className="form-control input-default mb-2"
              placeholder="Exemple: fa fa-bell"
              value={state.icon}
              onChange={(e) => handleState("icon", e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="formFile" className="form-label">
              Choisir une image
            </label>
            <input
              className="form-control"
              type="file"
              accept="image/*"
              onChange={handleFile}
            />
            {equipment.image ? (
              <div className="mt-4">
                <label className="form-label">Image actuelle</label>
                <img
                  src={asset_img(equipment.image)}
                  className="d-block"
                  height={150}
                  alt=""
                />
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="form-group mb-3">
            <label>Description</label>
            <textarea
              className="form-control"
              rows="4"
              onChange={(e) => handleState("description", e.target.value)}
              value={state.description}
            ></textarea>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-dark" onClick={handleClose}>
          Fermer
        </Button>
        <Button variant="secondary" onClick={save} disabled={loading}>
          Modifier
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default EditEquipment;
