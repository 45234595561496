import { useSelector } from "react-redux"

const CheckPermission = ({permission,children}) => {
    const currentUser = useSelector(state => state.auth)
    const checkName = currentUser?.auth.displayName

    const permissionLists = currentUser?.auth.permissions
    const checkPermission = permissionLists?.some((item) =>  item === permission )

    if (checkPermission || checkName === "Admin") return children
    return <div></div>
}

export default CheckPermission