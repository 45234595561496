import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";

function Request() {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Modal className="fade" show={open} centered onHide={() => handleClose()}>
        <Modal.Header>
          <Modal.Title>Remplissez le formulaire</Modal.Title>
          <Button
            onClick={() => handleClose()}
            variant=""
            className="btn-close"
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <div className="basic-form">
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="form-group ">
                <label>Votre requete : </label>
                <textarea
                  className="form-control py-3"
                  rows="10"
                  placeholder="Ex: Je n'arrive pas à ajouter les informations sur un client"
                  id="request"
                ></textarea>
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => handleClose()} variant="dark light">
            Fermer
          </Button>
          <Button variant="primary">Soumettre</Button>
        </Modal.Footer>
      </Modal>
      <div className="mt-4 text-center">
        <h3>Toujours pas de chance ? Nous pouvons aider!</h3>
        <p className="mb-4">
          Contactez-nous et nous vous répondrons dans les plus brefs délais.
        </p>
        <Button onClick={handleOpen}>Soumettre une demande</Button>
      </div>
    </>
  );
}

export default Request;
