import { createFormData } from "../helpers/services";
import axiosInstance from "./AxiosInstance";

export function createCall(postData) {
  return axiosInstance.post(`call/create`, createFormData(postData));
}

export function DeclineCall(id) {
  return axiosInstance.post(`call/canceled/${id}`);
}

export function answerCall(id) {
  return axiosInstance.post(`call/answer/${id}`);
}

export function stopCall(id) {
  return axiosInstance.post(`call/stop/${id}`, createFormData({
    time: "02:01",
  }));
}

export function storeNumber(numero) {
  return axiosInstance.post(`call/store/number`, createFormData({
    numero,
  }));
}