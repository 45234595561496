import Spinner from "react-bootstrap/Spinner";
import html2canvas from "html2canvas";
import { autoTable } from "jspdf-autotable";
import jsPDF from "jspdf";
import { Button, Card } from "react-bootstrap";
import pngwing from "../../../images/pngwing.png";
import { Link, useParams, useHistory } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { showFacture, archiveFacture } from "../../../services/Facture";
import { envoyer, modifier, telecharger } from "./action";
import logo from "../../../images/logo.jpg";    
import { sendFacture } from "../../../services/Facture";

function FactureGenerate() {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [loader, setLoader] = useState(false);
  
  console.log(data.checkouts)

  const downloadPDF = () => {
    setLoader(true);
    
    let docWidth = 150;
    let docHeight = 170 + data.checkouts.length * 10;
    let fontMiddleSize = 11;
    let fontSmallSize = 9;
    let doc = new jsPDF({
      format: [docWidth, docHeight],
    });

    // marge à gauche x : 10 ,, à droite x : 90,,,top : 10 
    // Première section - en haut à gauche
    doc.addImage(logo, 'JPEG', 10, 10, 9, 9); // logo
    doc.setFontSize(fontMiddleSize);
    doc.text('Hotel', 23, 17);
    doc.setFontSize(fontSmallSize);
    doc.text('129, Bd JOFFRE', 10, 30);
    doc.text('O387883477 / O328337743', 10, 35);
    doc.text('NIF: 400348443', 10, 40); 
    doc.text('STAT:56101312009000462', 10, 45);

    // Première section - en haut à droite
    doc.setFontSize(fontSmallSize);
    doc.text('Facture n° : ' + data.numero_facture, 90, 15);
    doc.text('Date de publication : ' + data.date_publication.split(" ")[0], 90, 30);
    doc.text("Date d'écheance : " + data.date_echeance.split(" ")[0], 90, 35);

    // séconde section - à gauche
    doc.text("pour", 10, 52);
    doc.setFontSize(12);
    doc.text(data.client.firstname+ ' ' +data.client.lastname, 10, 60);
    doc.setFontSize(fontSmallSize);
    doc.text(data?.client.ref +', '+data?.client.nature_pid +" : "+ data?.client.numero_pid, 10, 65);
    doc.text(data?.client.profession + ' ' +data?.client.nationality, 10, 70);
    doc.text(data?.client.adresse, 10, 75);
    doc.text(data?.client.telephone+', '+data?.client.email, 10, 80);
    
    // séconde section - à droite
    doc.text('Total dû : '+ data.montant + ' Ar', 90, 60);
    doc.text('Banque : '+ data.banque ?? "", 90, 65);
    doc.text("Pays : "+ data.pays ?? "", 90, 70);
    doc.text("IBAN : "+ data.iban ?? "" , 90, 75);

    // troisieme section : elements
    doc.setFontSize(fontMiddleSize);
    doc.text("Elements" , 10, 105);
    
    doc.text("Raison" , 10, 115);
    doc.text("Prix Total" , 75, 115);
    doc.text("Date" , 125, 115);
    doc.line(10, 118, docWidth - 10, 118)

    doc.setFontSize(fontSmallSize)
    // 'raison, prix, prix, prixTotal, updated_atsplit("T")[0]
    let checkouts_len = data.checkouts.length

    data.checkouts.map((element,index) => {
      let marge_top = 10 * index
      doc.text(element.raison , 10, 125 + marge_top);
      doc.text(element.prixTotal.toString() + ' Ar', 75, 125 + marge_top);
      doc.text(element.date.split(' ')[0] , 125, 125 + marge_top);
      doc.line(10, 129 + marge_top, docWidth - 9, 129 + marge_top)
    })

    // quatrieme section - à gauche
    let margin_y = 5 * checkouts_len
    doc.text('Sous Total : '+  data.montant + ' Ar', 10, 150 + margin_y);
    doc.text("Remise: 0 Ar", 10, 155 + margin_y);
    doc.text("Taxes: 0 "+ ' Ar', 10, 160 + margin_y);
    doc.text("Total: " + data.montant + "Ar", 10, 165 + margin_y);

    setLoader(false);
    doc.save(`${data?.client.ref}.pdf`)
  };

  const params = useParams();
  const id_facture = params.id_facture;

  const history = useHistory();

  useEffect(() => {
    showFacture(parseInt(id_facture))
      .then((res) => {
        console.log("res", res.data);
        setData(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const handleArchive = () => {
    archiveFacture(id_facture)
      .then( data => {
        setIsLoading(true);
        if(data.status === 200) {
          setTimeout(
            () => {
              setIsLoading(false);
              history.push('/invoices');
            }, 
          2000);
        }
      });
  }

  // Send facture to email
  const handleSend = (facture_publication) => {
    sendFacture(facture_publication)
      .then( (response) => {
        setIsLoading(true);

        if(data.status === 200) {
          setTimeout(
            () => {
              setIsLoading(false);
            }, 
          2000);
        }
      });
  }

  return isLoading ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "100px",
      }}
    >
      <Spinner animation="border" variant="secondary" />
    </div>
  ) : (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Card className="actual-receipt bg-white w-100 p-1" style={{ marginRight: '15px' }}>
        <Card.Body className="bg-white">
          <div className="row mt-3" >
            <div className="col-6">
              <div className="d-flex flex-row">
                <img
                  style={{
                    width: "40px",
                    height: "40px",
                    marginRight: "15px",
                    marginTop: "15px",
                  }}
                  src={logo}
                />
                <span className="ml-3 text-center fw-bold" style={{ marginTop: "15px",fontFamily: "Poppins" }}>
                   <br />
                  Hotel
                </span>
              </div>
              <div className="d-flex flex-column mt-3">
                <span>129, Bd JOFFRE</span>
                <span>O387883477 / O328337743</span>
                <span>NIF: 400348443</span>
                <span>STAT:56101312009000462</span>
              </div>
            </div>
            <div className='col-6 form-group mb-3 pt-4 d-flex flex-column text-left'>
                <span>
                  Facture: <b>{data.numero_facture}</b>
                </span>
                <span className="mt-3">
                  Date de publication : {data.date_publication.split(" ")[0]}
                </span>
                <span>
                  Date d'écheance : {data.date_echeance.split(" ")[0]}
                </span>
            </div>
          </div>
          <hr></hr>
          <div
            style={{
              display: "flex",
              marginTop: "40px",
              marginBottom: "40px",
              justifyContent: "space-between",
            }}
          >
            <div className="d-flex flex-column">
              <span style={{ fontWeight: "bold", fontSize: "20px" }}>
                Facturer pour:
              </span>
              <span>
                {data?.client.firstname} {data.client.lastname}{" "}
              </span>
              <span className="small">{data?.client.ref}</span>
              <span>{data?.client.nationality}</span>
              <span>{data?.client.nature_pid} : {data?.client.numero_pid}</span>
              <span className="mt-1">{data?.client.profession}</span>
              <span>{data?.client.adresse}</span>
              <span>{data?.client.telephone}</span>
              <span>{data?.client.email} </span>
            </div>
            <div className="d-flex flex-column">
              <span style={{ fontWeight: "bold", fontSize: "20px" }}>Facturer: </span>
              <span style={{ fontSize: "20px" }}>Code rapide: #123456PDJSG </span>
              <span>Total dû : {data.montant}</span>
              <span>Banque : {data.banque ?? ""}</span>
              <span>Pays : {data.pays ?? ""} </span>
              <span>IBAN : {data.iban ?? ""}</span>
            </div>
          </div>
          <hr></hr>
          <div
            style={{
              marginTop: "50px",
            }}
          >
            <p style={{ fontWeight: "bold", fontSize: "20px" }}>Eléments</p>
            <div className="table-responsive">
              <div
                id="example2_wrapper"
                className="dataTables_wrapper no-footer"
              >
                <table
                  id="example2"
                  className="table card-table display mb-4 shadow-hover default-table dataTablesCard dataTable no-footer"
                >
                  <thead>
                    <tr role="row">
                      <th className="sorting">Raison</th>
                      <th className="sorting">Prix Total</th>
                      <th className="sorting">Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.checkouts?.map((element, index) => (
                      <tr
                        role="row"
                        className="odd"
                        style={{
                          fontSize: "30px",
                        }}
                        key={index}
                      >
                        <td className="sorting_1">{element.raison}</td>
                        <td>{element.prixTotal}</td>
                        <td>{element.updated_at.split("T")[0]}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "100px",
              marginBottom: "100px",
            }}
          >
            
            <div className="d-flex flex-column medium">
              <span>Sous Total : {data.montant} Ar</span>
              <span>Remise: 0 Ar</span>
              <span>Taxes: 0 </span>
              <span>Total: {data.montant} Ar</span>
            </div>{" "}
          </div>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <Button
            variant="success"
            size="sm"
            style={{
              width: "100%",
              backgroundColor: "#116455",
              marginBottom: "10px",
              fontWeight: "bold",
            }}
            onClick={() => handleSend(data.date_publication)}
          >
            <i
              class="fas fa-solid fa-envelope"
              style={{ color: "#ffffff", marginRight: "10px" }}
            ></i>{" "}
            Envoyez
          </Button>
          <Button
            variant="light"
            size="sm"
            style={{
              width: "100%",
              backgroundColor: "#FFFF",
              color: "#116455",
              marginBottom: "10px",
              fontWeight: "bold",
            }}
            onClick={downloadPDF}
            disabled={!(loader === false)}
          >
            <i
              class="fas fa-solid fa-file-pdf"
              style={{ color: "#116455", marginRight: "10px" }}
            ></i>
            Télécharger en PDF
          </Button>

          <Button
            variant="light"
            size="sm"
            style={{
              width: "100%",
              backgroundColor: "#FFFF",
              color: "#116455",
              marginBottom: "10px",
              fontWeight: "bold",
            }}
            onClick={modifier(data.id)}
          >
            <i
              class="fas fa-light fa-file"
              style={{ color: "#116455", marginRight: "10px" }}
            ></i>
            Modifier
          </Button>
          <Link to={`/invoices/addAnotherAchat/${data.id}`}>
            <Button
              variant="success"
              size="sm"
              style={{
                backgroundColor: "#116455",
                width: "100%",
              }}
            >
              $ Ajouter un paiement
            </Button>
          </Link>

          <Button
              size="sm"
              style={{
                backgroundColor: "#ef4444",
                width: "100%",
                marginTop: "10px",
                fontWeight: "bold",
              }}
              onClick={handleArchive}
            >
              Supprimer
            </Button>
        </Card.Body>
      </Card>
    </div>
  );
}

export default FactureGenerate;
