import React from "react";
import ReservationStats from "../components/tableauBord/ChiffreAffaireStat";
import { Row } from "react-bootstrap";
import RemplissageJour from "../components/tableauBord/RemplissageJour";
import RemplissageStats from "../components/tableauBord/RemplissageStats";
import CommingSoon from "../components/CommingSoon";

const Dashboard = () => {

    return (
        <div style={{ display: "flex", }}>
            {/* <CommingSoon /> */}
            <Row gutter={16}>
                <RemplissageJour />
                <div className="col-md-6">
                    <RemplissageStats />
                </div>
                <div className="col-md-6">
                    <ReservationStats />
                </div>
            </Row>
        </div>
    );
}

export default Dashboard;