import React, { useEffect, useState } from "react";
import { Button, Nav } from "react-bootstrap";
import moment from "moment";
import "moment/locale/fr";
import DateRangePicker from "react-bootstrap-daterangepicker";
import CheckPermission from "../../../templates/components/CheckPermission/CheckPermission";

moment().locale("fr");

const TABS = [
  { label: "Tout", value: null },
  { label: "Disponible", value: "disponible" },
  { label: "Indisponible", value: "indisponible" },
];

function Header({handleAll, handleDisponible, handleIndisponible}) {

  const [status, setStatus] = useState(null);

  const handleStatus = (tab) => {
    setStatus(tab);
    if (tab === "disponible") {
      handleDisponible();
    } else if (tab === "indisponible") {
      handleIndisponible();
    } else {
      handleAll();
    }
  }

  return (
    <div className="d-flex justify-content-between align-items-center flex-wrap">
      <div className="card-action coin-tabs mb-2">
        <Nav as="ul" className="nav nav-tabs">
          {TABS.map((tab, index) => (
            <Nav.Item as="li" className="nav-item" key={index}>
              <Nav.Link
                className={`nav-link ${status === tab.value ? "active" : ""}`}
                onClick={() => handleStatus(tab.value)}
              >
                {tab.label}
              </Nav.Link>
            </Nav.Item>
          ))}
        </Nav>
      </div>
    </div>
  );
}

export default Header;
