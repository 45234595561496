import swal from "sweetalert";
import { toast } from "react-toastify";
import Form from "react-bootstrap/Form";
import { useDisabled } from "./action";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button, Card, Row, Col } from "react-bootstrap";
import { AddAnotherAchatService } from "../../../services/Facture";

function AddAnotherAchat() {
  const ToastOption = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
  };

  const facture_id = useParams().id_facture;

  const [isLoading, setIsLoading] = useState(false);
  const [inputFields, setInputFields] = useState([
    { element: "", description: "", prix: 0, quantite: 0, total: 0 },
  ]);

  const [diseabledAdd, setDiseabledAdd] = useState(true);
  const disabled = useDisabled(inputFields);

  useEffect(() => {
    if (inputFields.length > 0) {
      const maxIdInputFields = inputFields.length - 1;
      if (
        inputFields[maxIdInputFields].element !== "" &&
        inputFields[maxIdInputFields].description !== "" &&
        inputFields[maxIdInputFields].prix > 0 &&
        inputFields[maxIdInputFields].quantite > 0
      ) {
        setDiseabledAdd(false);
      } else {
        setDiseabledAdd(true);
      }
    }
  }, [inputFields]);

  const handleAddFields = () => {
    const values = [...inputFields];
    values.push({
      element: "",
      description: "",
      prix: 0,
      quantite: 0,
      total: 0,
    });
    setInputFields(values);
  };

  const handleRemoveFields = (index) => {
    let values = [...inputFields];
    values.splice(index, 1);
    setInputFields(values);
  };

  const handleChange = (index, event) => {
    const values = [...inputFields];
    if (event.target.name === "element") {
      values[index].element = event.target.value;
    } else if (event.target.name === "description") {
      values[index].description = event.target.value;
    } else if (event.target.name === "prix") {
      values[index].prix = event.target.value;
    } else if (event.target.name === "quantite") {
      values[index].quantite = event.target.value;
    }
    values[index].total = values[index].prix * values[index].quantite;
    setInputFields(values);
  };

  const save = () => {
    setIsLoading(true);

    const data = [];
    if (inputFields.length > 0) {
      inputFields.forEach((item) => {
        if (
          item.element !== "" &&
          item.description !== "" &&
          item.prix !== 0 &&
          item.quantite !== 0
        ) {
          data.push({
            element: item.element,
            description: item.description,
            prix: parseInt(item.prix),
            quantite: parseInt(item.quantite),
          });
        }
      });
    }

    AddAnotherAchatService(facture_id, {
      achats: data.length > 0 ? JSON.stringify(data) : JSON.stringify([]),
    })
      .then((res) => {
        setIsLoading(false);
        swal({
          title: "Succès",
          text: "Achat ajouté avec succès",
          icon: "success",
          buttons: {
            confirm: "Oui ",
            cancel: "Non",
          },
        }).then((res) => {
          window.location.href = `invoices/generate/${facture_id}`;
        });
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(
          `Une erreur s'est produite lors de l'ajout d'achats : ${err.message}`,
          ToastOption
        );
      });
  };

  console.log(inputFields)

  return (
    <Card>
      <Card.Body>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "20px",
          }}
        >
          <Button
            className="ms-3"
            variant="secondary"
            disabled={disabled}
            onClick={handleAddFields}
          >
            <i
              className="fa fa-plus"
              style={{
                marginRight: "10px",
              }}
            ></i>
            Ajouter un autre achat
          </Button>
        </div>
        <div>
          <table
            id="example2"
            className="table card-table display mb-4 shadow-hover default-table dataTablesCard dataTable no-footer"
          >
            <thead>
              <tr role="row">
                <th className="sorting_asc">Elément</th>
                <th className="sorting">Description</th>
                <th className="sorting">PU</th>
                <th className="sorting">Quantité</th>
                <th className="sorting">Total</th>
                <th className="sorting">Action</th>
              </tr>
            </thead>
            <tbody>
              {inputFields?.map(
                (inputField, index) =>
                  inputField.element &&
                  inputField.description &&
                  inputField.prix > 0 &&
                  inputField.quantite > 0 && (
                    <tr
                      role="row"
                      className="odd"
                      style={{
                        fontSize: "30px",
                      }}
                    >
                      <td className="sorting_1">{inputField.element}</td>
                      <td>{inputField.description}</td>
                      <td>{inputField.prix}</td>
                      <td>{inputField.quantite}</td>
                      <td>{inputField.total}</td>
                      <td>
                        <Button
                          className="ms-3"
                          variant="danger"
                          onClick={() => {
                            handleRemoveFields(index);
                          }}
                        >
                          <i class="fa fa-trash" aria-hidden="true"></i>
                        </Button>
                      </td>
                    </tr>
                  )
              )}
            </tbody>
          </table>
        </div>
        <div
          style={{
            marginTop: "100px",
          }}
        >
          {inputFields?.map((inputField, index) => (
            <Row
              key={index}
              style={{
                display: "flex",

                marginBottom: "20px",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Col>
                <Form.Label
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  Elément
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Homard grillé au four"
                  name="element"
                  defaultValue={inputField.element}
                  onChange={(event) => {
                    handleChange(index, event);
                  }}
                />
              </Col>
              <Col>
                <Form.Label
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  Description
                </Form.Label>
                <Form.Control
                  type="text"
                  name="description"
                  placeholder="Description"
                  defaultValue={inputField.description}
                  onChange={(event) => {
                    handleChange(index, event);
                  }}
                />
              </Col>
              <Col>
                <Form.Label
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  Prix unitaire
                </Form.Label>
                <Form.Control
                  type="number"
                  name="prix"
                  defaultValue={inputField.prix}
                  onChange={(event) => {
                    handleChange(index, event);
                  }}
                />
              </Col>
              <Col>
                <Form.Label
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  Quantité
                </Form.Label>
                <Form.Control
                  type="number"
                  name="quantite"
                  defaultValue={inputField.quantite}
                  onChange={(event) => {
                    handleChange(index, event);
                  }}
                />
              </Col>
              <Col
                sm={1}
                style={{
                  marginTop: "30px",
                }}
              >
                <Button
                  className="ms-3"
                  variant="danger"
                  disabled={inputFields.length === 1}
                  onClick={() => handleRemoveFields(index)}
                >
                  <i class="fa fa-minus" aria-hidden="true"></i>
                </Button>
              </Col>
            </Row>
          ))}
        </div>
        <Button
          className="ms-3"
          variant="secondary"
          style={{
            marginTop: "30px",
            width: "100%",
          }}
          disabled={diseabledAdd || isLoading}
          onClick={save}
        >
          <i
            className="fa fa-plus"
            style={{
              marginRight: "10px",
            }}
          ></i>
          Ajouter
        </Button>
      </Card.Body>
    </Card>
  );
}

export default AddAnotherAchat;
