import React, { useState } from "react";
import { onlineClients } from "../../../services/ClientService";
import Loading from "../../components/Loading";

import default_img from "../../../images/avatar.svg";
import { asset_img } from "../../../helpers/data";
import { Link } from "react-router-dom";

const OnlineClient = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const getData = () => {
    onlineClients()
      .then(({ data }) => {
        setData(data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    getData();
  }, []);

  return (
    <>
    <h2>Clients connectés ({data.length})</h2>
      <div className="row mt-4">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body p-0"></div>
          </div>
        </div>
      </div>
      <table
        id="example2"
        className="table card-table display mb-4 shadow-hover default-table dataTablesCard dataTable no-footer"
      >
        <tbody>
          {loading ? (
            <Loading color="var(--primary)" />
          ) : (
            data.map((item, index) => (
              <tr
                role="row"
                className={`${index % 2 === 0 ? "odd" : "even"} p-x`}
                key={index}
              >
                <div className="concierge-bx d-flex align-items-center p-3">
                  <img
                    className="me-3 rounded"
                    src={item.photo ? asset_img(item.photo) : default_img}
                    alt=""
                    style={{ objectFit: "cover" }}
                  />
                  <div>
                    <h5 className="fs-16 mb-0 text-nowrap">
                      <Link to={`/client/${item.id}`} className="text-black">
                        {`${item.name}`}
                      </Link>
                    </h5>
                    <span className="text-primary fs-14">#{item.ref}</span>
                  </div>
                </div>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </>
  );
};
export default OnlineClient;
