import React from "react";

const CommingSoon = () => {
    return (
        <div className="container-fluid">
        <div className="row">
            <div className="col-4"></div>
            <div className="col-4">
                <div className="card">
                    <div className="card-body">
                        <h4 className="card-title">Bientôt disponible</h4>
                        <h6 className="card-subtitle"></h6>
                        <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT2SL9zFbQALM3M-4tpmz_n1CIPXDyuJRHjXA&usqp=CAU" alt="Comming Soon" />
                    </div>
                </div>
            </div>
        </div>
        </div>
    );
    }
export default CommingSoon;
