import List from "../components/Orders/List";
import Header from "../components/Orders/Header";
import "bootstrap-daterangepicker/daterangepicker.css";
import { getOrders, getReservationCount } from "../../services/OrderService";
import  { getPaginationData } from "../../services/PaginationService";
import React, { useState, useRef, useEffect } from "react";
import CommingSoon from "../components/CommingSoon";

const Orders = () => {
  const [datas, setDatas] = useState([]);
  const [dataFilter, setDataFilter] = useState([]);
  const [loading, setLoading] = useState(false);
  const [status,setStatus] = useState("");
  const [reservation_counts, setReservationCounts] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const sort = 10;

  // state pour la table et la pagination
  const [isChangeDataTable, setIsChangeDataTable] = useState({});

  // use effect
  useEffect(() => {
    setLoading(true);
    getOrders()
      .then(({ data }) => {
        if (data.reservations.length) {
          setDatas(data.reservations);
          setDataFilter(data.reservations);

        } else {
          setDatas([]);
          setDataFilter([]);
        }
      })
      .finally(() => setLoading(false));
  }, [status]);

  // pagination
  const pagination = (page_number) => {
    setLoading(true);
    getPaginationData("reservation", page_number)
      .then(({ data }) => {
        if (data.length) {
          setDatas(data);
          setDataFilter(data);
          setCurrentPage(page_number);
        } else {
          setDatas([]);
          setDataFilter([]);
        }
      }
      )
      .finally(() => setLoading(false));
  };
  
  useEffect(() => {
    setDataFilter(
      dataFilter.slice(isChangeDataTable.frist, isChangeDataTable.sec)
    );
  }, [isChangeDataTable]);

  // resrvation count
  useEffect(() => {
    getReservationCount()
      .then((response) => {
        setReservationCounts(response.data.count);
      })
  }, []);

  const handleData = (status) => {
    if (status === null) {
      setDataFilter(datas);
    } else {
      setDataFilter(datas.filter((order) => order.status === status));
    }
  };


  const chackbox = document.querySelectorAll(".sorting_1 input");
  const motherChackBox = document.querySelector(".sorting_asc input");
  const chackboxFun = (type) => {
    for (let i = 0; i < chackbox.length; i++) {
      const element = chackbox[i];
      if (type === "all") {
        if (motherChackBox.checked) {
          element.checked = true;
        } else {
          element.checked = false;
        }
      } else {
        if (!element.checked) {
          motherChackBox.checked = false;
          break;
        } else {
          motherChackBox.checked = true;
        }
      }
    }
  };

  return (
    <>
      {/* <CommingSoon /> */}
      <Header handleData={handleData} />
      <div className="row mt-4">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body p-0">
              <List
                chackboxFun={chackboxFun}
                data={dataFilter}
                loading={loading}
                sort={sort}
                setStatus={setStatus}
                reservation_counts={reservation_counts}
                setReservationCounts={ setReservationCounts }
                currentPage={currentPage}
                pagination={pagination}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Orders;
