import { createFormData } from "../helpers/services";
import axiosInstance from "./AxiosInstance";

export function getChambres() {
  return axiosInstance.get(`chambre/liste`);
}
export function getAllChambres() {
  return axiosInstance.get(`chambre/liste/all`);
}
export function getNextChambres(start_point, end_point) {
  return axiosInstance.get('chambre/browse/next/'+start_point + '/' + end_point);
}

export function getPrevChambres(start_point, end_point) {
  return axiosInstance.get('chambre/browse/prev/'+start_point + '/' + end_point);
}

export function getChambresCount() {
  return axiosInstance.get(`chambre/browse/count`);
}

export function getChambreReservation(id) {
  return axiosInstance.get(`reservation/chambre/${id}`);
}

export function getChambresDetail(id) {
  return axiosInstance.get(`chambre/show/${id}`);
}

export function editChambre(id, data) {
  return axiosInstance.post(`chambre/edit/${id}`, createFormData(data));
}

export function getChambresEquipment(id) {
  return axiosInstance.get(`chambre_equipement/get/${id}`);
}

export function createChambre(data) {
  return axiosInstance.post(`chambre/create`, createFormData(data));
}

export function createEquipment(data) {
  return axiosInstance.post(`chambre_equipement/create`, createFormData(data));
}

export function editEquipment(id, data) {
  return axiosInstance.post(
    `chambre_equipement/edit/${id}`,
    createFormData(data)
  );
}

export function deleteEquipment(id) {
  return axiosInstance.post(`chambre_equipement/delete/${id}`);
}


export function getChambreWithStatusByDate(date)
{
  return axiosInstance.get('chambre/liste-chambre-by-date?date='+date);
}