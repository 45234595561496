import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { asset_img } from "../../../helpers/data";

import default_img from '../../../images/defaults/support-img.png'

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div className="owl-nav">
      <div className="owl-next fas fa-arrow-right" onClick={onClick} />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div className="owl-nav">
      <div
        className=" owl-prev fas fa-arrow-left"
        onClick={onClick}
        style={{ zIndex: 1 }}
      />
    </div>
  );
}

const RoomSlider = ({ chambre, equipements }) => {
  const settings = {
    dots: false,
    infinite: true,
    fade: true,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 591,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <Slider
        className="guest-carousel owl-carousel owl-carousel owl-loaded owl-drag owl-dot"
        {...settings}
      >
        <div className="item">
          {chambre ? (
            <div className="rooms">
              <img
                src={chambre.image ? asset_img(chambre.image) : default_img}
                alt=""
                style={{ width: "100%" }}
              />
              {/* <div className="booked">
                <p className="fs-20 font-w500">BOOKED</p>
              </div> */}
              <div className="img-content">
                <h4 className="fs-24 font-w600 text-white">{chambre.name}</h4>
                <p className="text-white"></p>
              </div>
            </div>
          ) : (
            <div className="rooms" style={{ backgroundColor: "#545454" }}>
              <div className="p-4">
                <h4 className="fs-24 font-w600 text-white">Aucune information disponible</h4>
              </div>
            </div>
          )}
        </div>
        {equipements.map((equipement) => (
          <div className="item" key={equipement.id}>
            <div className="rooms">
              <img
                src={equipement.image ? asset_img(equipement.image) : default_img}
                alt=""
                style={{ width: "100%" }}
              />
              <div className="img-content">
                <h4 className="fs-24 font-w500 text-white">
                  {equipement.title}
                </h4>
                <p className="text-white">{equipement.description}</p>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </>
  );
};
export default RoomSlider;
