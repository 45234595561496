import React from "react";
import { Link } from "react-router-dom";
import Actions from "./Actions";
import { asset_img } from "../../../helpers/data";
import Loading from "../Loading";

import default_img from "../../../images/avatar.svg";
import { formatDate, formatDateTime, formatTime } from "../../../helpers/dates";

function List({
  chackboxFun,
  data,
  loading,
  sort,
  handleEdit,
  end_point,
  start_point,
  clients_counts,
  currentPage,
  paginate
}) {

  const status_list = [
    {color: 'warning', text: 'En cours'},
    {color: 'success', text: 'Payé'},
    {color: 'primary', text: 'Cloturé'},
    {color: 'secondary', text: 'En litige'},
    {color: 'danger', text: 'Annulée'}
  ]

  return (
    <div className="table-responsive">
      <div id="example2_wrapper" className="dataTables_wrapper no-footer">
        <table
          id="example2"
          className="table card-table display mb-4 shadow-hover default-table dataTablesCard dataTable no-footer"
        >
          <thead>
            <tr role="row">
              <th className="sorting_asc bg-none">
                <div className="form-check  style-1">
                  <input
                    type="checkbox"
                    onClick={() => chackboxFun("all")}
                    className="form-check-input"
                    id="checkAll"
                    required=""
                  />
                </div>
              </th>
              <th className="sorting_asc">Client</th>
              <th className="sorting">Commande</th>
              <th className="sorting">Arrivé</th>
              <th className="sorting">Départ</th>
              <th className="sorting">Requete spéciale</th>
              <th className="sorting">Chambre</th>
              <th className="sorting">Status</th>
              <th className="">Actions</th>
            </tr>
          </thead>
          <tbody>
            {!loading ? (
              data && data.length > 0 ? (
                data.map((item, index) => (
                  <tr
                    role="row"
                    className={index % 2 === 0 ? "odd" : "even"}
                    key={index}
                  >
                    <td className="sorting_1">
                      <div className="form-check  style-1">
                        <input
                          type="checkbox"
                          onClick={() => chackboxFun()}
                          className="form-check-input"
                          id="customCheckBox2"
                          required=""
                        />
                      </div>
                    </td>
                    <td>
                      <div className="concierge-bx d-flex align-items-center">
                        <img
                          className="me-3 rounded"
                          src={item.photo ? asset_img(item.photo) : default_img}
                          alt=""
                          style={{ objectFit: "cover" }}
                        />
                        <div>
                          <h5 className="fs-16 mb-0 text-nowrap">
                            <Link
                              to={`/client/${item.id}`}
                              className="text-black"
                            >
                              {`${item.firstname} ${item.lastname}`}
                            </Link>
                          </h5>
                          <span className="text-primary fs-14">
                            #{item.ref}
                          </span>
                        </div>
                      </div>
                    </td>
                    {item.last_reservation &&
                    item.last_reservation.chambre_id ? (
                      <>
                        <td className="text-nowrap">
                          <span>
                            {formatDateTime(
                              item.last_reservation.date_reservation
                            )}
                          </span>
                        </td>
                        <td>
                          <div>
                            <h5 className="text-nowrap">
                              {formatDate(item.last_reservation.date_arrivee)}
                            </h5>
                            <span>
                              {formatTime(item.last_reservation.date_arrivee)}
                            </span>
                          </div>
                        </td>
                        <td>
                          <div>
                            <h5 className="text-nowrap">
                              {formatDate(item.last_reservation.date_depart)}
                            </h5>
                            <span>
                              {formatTime(item.last_reservation.date_depart)}
                            </span>
                          </div>
                        </td>
                        <td className="request">
                          <Link to={"#"} className="btn btn-sm">
                            Voir les notes
                          </Link>
                        </td>
                        <td>
                          <Link
                            to={`/room/${item.last_reservation.chambre_id}`}
                            className="font-w500 text-dark"
                          >
                            {item.last_reservation.chambre_name}
                          </Link>
                        </td>
                        <td>
                          <div className={"d-flex flex-row nowrap w-100 text-center text-" + status_list[item.last_reservation.status].color}>
                              { status_list[item.last_reservation.status].text}
                          </div>
                        </td>
                      </>
                    ) : (
                      <td colSpan={6} className="text-center">
                        Aucune reservation enregistrée
                      </td>
                    )}
                    <td>
                      <Actions
                        client={item}
                        handleEdit={() => handleEdit(item)}
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6" className="text-center  fs-20">
                    Aucun client trouvé
                    <br />
                  </td>
                </tr>
              )
            ) : (
              <tr>
                <td colSpan="6" className="text-center fs-20">
                  <Loading color="var(--secondary)" />
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {data.length > 0 && (
          <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-3">
            <div className="dataTables_info">
              Affichage de { 
                currentPage === 1 ? 1 : (currentPage - 1) * sort + 1
              } à { 
                currentPage === 1 ? sort : data.length === sort ? currentPage * sort : (currentPage - 1) * sort + data.length
              } sur {clients_counts} entrées
            </div>
            <div
              className="dataTables_paginate paging_simple_numbers mb-0"
              id="example2_paginate"
            >
              {
                currentPage === 1 ? '' : 
                (
                  <>
                    <button
                    className="paginate_button previous disabled"
                    onClick={() => paginate(currentPage - 1)}
                    >
                    <i className="fa fa-angle-double-left" aria-hidden="true"></i>
                    </button>
                </>
              )
              }
              
              
              <span>
                  <div
                    className='paginate_button '
                  >
                    { currentPage }  
                  </div>
              </span>
                    
              {
                currentPage >= Math.ceil(clients_counts / sort) ? '' :
              (
              <>
                <button
                  className="paginate_button next"
                  onClick= { ()=> paginate(currentPage + 1)}
                >
                  <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                </button>
              </>
              )
              }
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default List;
