import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";

const CLOCK = 10 * 60 * 1000;

function Search() {
  const [greeting, setGreeting] = useState("Bonjour");

  const handleGreeting = (hour) => {
    if (hour >= 17) {
      setGreeting("Bonsoir");
    } else {
      setGreeting("Bonjour");
    }
  };

  useEffect(() => {
    const handleTime = setInterval(() => {
      const d = new Date();
      handleGreeting(d.getHours());
    }, CLOCK);

    return () => clearInterval(handleTime);
  }, []);

  return (
    <div className="card py-5 px-4 mb-4 w-100">
      <Container className="mt-0">
        <h2 className="text-center mb-4">
          {greeting}, comment pouvons-nous vous aider ?
        </h2>
        <div className="basic-form">
          <form className="d-flex justify-content-center" action="#">
            <div className="input-group mb-3" style={{ width: "80%" }}>
              <input type="text" placeholder="Rechercher..." className="form-control" />
              <button className="btn btn-primary" type="button">
                Rechercher
              </button>
            </div>
          </form>
        </div>
      </Container>
    </div>
  );
}

export default Search;
