import React from "react";
import { Link } from "react-router-dom";
import Actions from "./Actions";
import { asset_img } from "../../../helpers/data";
import Loading from "../Loading";

import default_img from "../../../images/avatar.svg";
import { formatDate, formatDateTime, formatTime } from "../../../helpers/dates";
import dayjs from "dayjs";

function List({
  data,
  loading,
  // sort
}) {

  const status_list = 
    {
      'disponible': 'success',
      'indisponible': 'primary',
    }

    const capitalizeFirstLetter = (str) => {
      return str.charAt(0).toUpperCase() + str.slice(1);
  }
  

  return (
    <div className="table-responsive">
      <div id="example2_wrapper" className="dataTables_wrapper no-footer">
        <table
          id="example2"
          className="table card-table display mb-4 shadow-hover default-table dataTablesCard dataTable no-footer"
        >
          <thead>
            <tr role="row">

              <th className="sorting_asc">Chambre</th>
              <th className="sorting">Status</th>
              <th className="">Actions</th>
            </tr>
          </thead>
          <tbody>
            {!loading ? (
              data && data.length > 0 ? (
                data.map((item, index) => (
                  <tr
                    role="row"
                    className={index % 2 === 0 ? "odd" : "even"}
                    key={index}
                  >

                    <td>
                      <div className="concierge-bx d-flex align-items-center">
                        <img
                          className="me-3 rounded"
                          src={item.image ? asset_img(item.image) : default_img}
                          alt=""
                          style={{ objectFit: "cover" }}
                        />
                        <div>
                          <h5 className="fs-16 mb-0 text-nowrap">
                            <Link
                              to={`/room/${item.chambre_id}`}
                              className="text-black"
                            >
                              {`${item.name}`}
                            </Link>
                          </h5> 
                          <span className="text-primary fs-14">
                            #{item.ref}
                          </span>
                        </div>
                      </div> 
                    </td>
                    <td>
                      <span
                        className={`badge bg-${item.status == "disponible" ? "success" : "danger" } text-white`}
                      >
                        {item.status == "disponible" ?
                         "Disponible" 
                         : (capitalizeFirstLetter(item.status)
                          //  `${capitalizeFirstLetter(item.status)} jusqu'à ${dayjs(item.date_depart).format('DD/MM/YYYY HH:mm')}`
                         )}
                      </span>
                    </td>
                    <td>
                      <Actions
                        room={item}
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6" className="text-center  fs-20">
                    Aucune Chambre trouvé
                    <br />
                  </td>
                </tr>
              )
            ) : (
              <tr>
                <td colSpan="6" className="text-center fs-20">
                  <Loading color="var(--secondary)" />
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {/* {data.length > 0 && (
          <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-3">
            <div className="dataTables_info">
              Affichage de { 
                currentPage === 1 ? 1 : (currentPage - 1) * sort + 1
              } à { 
                currentPage === 1 ? sort : data.length === sort ? currentPage * sort : (currentPage - 1) * sort + data.length
              } sur {clients_counts} entrées
            </div>
            <div
              className="dataTables_paginate paging_simple_numbers mb-0"
              id="example2_paginate"
            >
              {
                currentPage === 1 ? '' : 
                (
                  <>
                    <button
                    className="paginate_button previous disabled"
                    onClick={() => paginate(currentPage - 1)}
                    >
                    <i className="fa fa-angle-double-left" aria-hidden="true"></i>
                    </button>
                </>
              )
              }
              
              
              <span>
                  <div
                    className='paginate_button '
                  >
                    { currentPage }  
                  </div>
              </span>
                    
              {
                currentPage >= Math.ceil(clients_counts / sort) ? '' :
              (
              <>
                <button
                  className="paginate_button next"
                  onClick= { ()=> paginate(currentPage + 1)}
                >
                  <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                </button>
              </>
              )
              }
            </div>
          </div>
        )} */}
      </div>
    </div>
  );
}

export default List;
