import React from 'react';
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import {Tab, Nav} from 'react-bootstrap';
import ChartBarStatRempDaily from './ChartBarStatRempDaily';
import ChartBarStatRempHebd from './ChartBarStatRempHebd';
import ChartLineStatRempMonth from './ChartLineStatRempMonth';
import ChartLineStatRempYear from './ChartLineStatRempYear';




const RemplissageStats = ()=> {
	return(
		<>
			<Tab.Container defaultActiveKey="Daily">
				<div className="card">
					<div className="card-header border-0 flex-wrap">
						<h4 className="fs-20">Statistique de remplissage</h4>
						<div className="card-action coin-tabs">
							<Nav as="ul" className="nav nav-tabs" >
								<Nav.Item as="li" className="nav-item">
									<Nav.Link eventKey="Daily" >Journalier</Nav.Link>
								</Nav.Item>
								<Nav.Item as="li" className="nav-item">
									<Nav.Link eventKey="Weekly">Hebdomadaire</Nav.Link>
								</Nav.Item>
								<Nav.Item as="li" className="nav-item">
									<Nav.Link className="nav-link" eventKey="Monthly">Mois</Nav.Link>
								</Nav.Item>
								<Nav.Item as="li" className="nav-item">
									<Nav.Link className="nav-link" eventKey="Yearly">Année</Nav.Link>
								</Nav.Item>
							</Nav>
						</div>
					</div>
					<div className="card-body pb-0">
						<div className="d-flex flex-wrap">
							{/* <span className="me-sm-5 me-0 font-w500">
								<svg className="me-1" xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13">
								  <rect  width="13" height="13" fill="#135846"/>
								</svg>
								Non Rempli
							</span>
							<span className="me-sm-5 ms-0 font-w500">
								<svg className="me-1" xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13">
								  <rect  width="13" height="13" fill="#E23428"/>
								</svg>
								Rempli
							</span> */}
						</div>
						<Tab.Content>							
							<Tab.Pane className="tab-pane" eventKey="Daily">
								<div id="chartBar" className="chartBar">
									<ChartBarStatRempDaily />
								</div>
							</Tab.Pane>
							<Tab.Pane className="tab-pane" eventKey="Weekly">
								<div id="chartBar1" className="chartBar">
									<ChartBarStatRempHebd />
								</div>
							</Tab.Pane>
							<Tab.Pane className="tab-pane" eventKey="Monthly">
								<div id="chartBar2" className="chartBar">
									<ChartLineStatRempMonth />
								</div>
							</Tab.Pane>
							<Tab.Pane className="tab-pane" eventKey="Yearly">
								<div id="chartBar2" className="chartBar">
									<ChartLineStatRempYear />
								</div>
							</Tab.Pane>
						</Tab.Content>		
					</div>
				</div>
			</Tab.Container>
		</>
	)
}
export default RemplissageStats;