import React from "react";
import { Button, Modal } from "react-bootstrap";
import { createUser, getRoles } from "../../../services/UserService";
import { validateForm } from "../../../helpers/users";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const defaultState = {
  name: "",
  email: "",
  password: "",
  password_confirmation: "",
  role: null,
};

const ToastOption = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
};

function Add({ show, handeClose, handleUsers }) {
  const [state, setState] = React.useState(defaultState);
  const [roles, setRoles] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [confirmShowPassword, setConfirmShowPassword] = React.useState(false);

  const notifyError = (message) => toast.error(message, ToastOption);

  const handleRoles = () =>
    getRoles().then(({ data }) => {
      setRoles(data);
    });

  const handleState = (name, value) =>
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));

  const save = () => {
    if (validateForm(state)) {
      setLoading(true);
      createUser(state)
        .then(() => {
          toast.success(`Compte enregistré`, ToastOption);
          handleUsers();
          setState(defaultState);
        })
        .catch((err) => {
          notifyError(`Erreur pendant l'enregistrement`);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      notifyError(`Veuillez remplir tous les champs`);
    }
  };

  React.useEffect(() => {
    handleRoles();
  }, [show]);

  React.useEffect(() => {
    if (roles && roles.length) {
      handleState("role", roles[0].id);
    }
  }, [roles]);

  return (
    <Modal
      show={show}
      onHide={handeClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Ajouter un compte
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 class="mb-4">Entrez les informations sur le compte</h4>
        <div>
          <div className="form-group mb-3">
            <label>Nom (complet)</label>
            <input
              type="text"
              className="form-control input-default mb-2"
              placeholder="Nom"
              value={state.name}
              onChange={(e) => handleState("name", e.target.value)}
            />
          </div>
          <div className="form-group mb-3">
            <label>Email</label>
            <input
              type="email"
              className="form-control input-default mb-2"
              placeholder="Email"
              value={state.email}
              onChange={(e) => handleState("email", e.target.value)}
            />
          </div>
          <div className="form-group mb-3">
            <label>Mot de passe ()</label>
            <div style={{ position: "relative" }}>
              <input
                type={showPassword ? "text" : "password"}
                className="form-control input-default mb-2"
                placeholder="Mot de passe"
                value={state.password}
                onChange={(e) => handleState("password", e.target.value)}
              />
              <FontAwesomeIcon
                icon={showPassword ? faEyeSlash : faEye}
                onClick={() => setShowPassword((current) => !current)}
                style={{
                  position: "absolute",
                  fontSize: "16px",
                  right: "10px",
                  top: "15px",
                }}
              />
            </div>
          </div>
          <div className="form-group mb-3">
            <label>Confirmer le mot de passe</label>
            <div style={{ position: "relative" }}>
              <input
                type={confirmShowPassword ? "text" : "password"}
                className="form-control input-default mb-2"
                placeholder="Confirmez le mot de passe"
                value={state.password_confirmation}
                onChange={(e) =>
                  handleState("password_confirmation", e.target.value)
                }
              />
              <FontAwesomeIcon
                icon={confirmShowPassword ? faEyeSlash : faEye}
                onClick={() => setConfirmShowPassword((current) => !current)}
                style={{
                  position: "absolute",
                  fontSize: "16px",
                  right: "10px",
                  top: "15px",
                }}
              />
            </div>
          </div>
          <div className="form-group mb-3">
            <label>Rôle</label>
            <select
              value={state.role}
              className="form-control"
              onChange={(e) => handleState("role", e.target.value)}
            >
              {roles.map((role, index) => (
                <option value={role.id} key={index}>
                  {role.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-dark" onClick={handeClose}>
          Fermer
        </Button>
        <Button variant="primary" onClick={save} disabled={loading}>
          Ajouter
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default Add;
