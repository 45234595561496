import React, { useState } from "react";

import { Link } from "react-router-dom";
import NavApp from "./NavApp";

const Header = ({ onNote }) => {
	const [searchBut, setSearchBut] = useState(false);

	return (
		<div className="header border-bottom">
			<div className="header-content">
				<nav className="navbar navbar-expand">
					<div className="collapse navbar-collapse justify-content-between">
						<div className="header-left">
							<div
								className="dashboard_bar"
								style={{ textTransform: "capitalize" }}
							>
								Bienvenue
							</div>
						</div>
						<div className="nav-item d-flex align-items-center">
							<div className="input-group search-area">
								<input type="text"
									className={`form-control ${searchBut ? "active" : ""}`}
									placeholder="Rechercher..."
								/>
								<span className="input-group-text" onClick={() => setSearchBut(!searchBut)}>
									<Link to={"#"}><i className="flaticon-381-search-2"></i></Link>
								</span>
							</div>
						</div>
						<NavApp onNote={onNote} />
					</div>
				</nav>
			</div>
		</div>
	);
};

export default Header;
