import React, { useEffect } from 'react'
import CalendarComponent from '../components/Calendar'
import Header from '../components/calendar/Header'
import List from '../components/calendar/List'
import { getChambreWithStatusByDate } from '../../services/ChambreService'
import dayjs from 'dayjs';

function Calendar() {

  const [data, setData] = React.useState([])
  const [dataSource, setDataSource] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [selectedDate, setSelectedDate] = React.useState(dayjs().format('YYYY-MM-DD'))

  const handleData = () => {
    getChambreWithStatusByDate(selectedDate).then((response) => {
      setData(response.data)
      setDataSource(response.data)
      setLoading(false)
    }).catch((error) => {
      console.log(error)
      setLoading(false)
    })
  }

  const onChangeDate = (date) => {
    setSelectedDate(dayjs(date).format('YYYY-MM-DD'))
    setLoading(true)
    getChambreWithStatusByDate(dayjs(date).format('YYYY-MM-DD')).then((response) => {
      setData(response.data)
      setDataSource(response.data)
      setLoading(false)
    }).catch((error) => {
      console.log(error)
      setLoading(false)
    })
  }

  const handleDisponible = () => {
    const filter = dataSource.filter(item => item.status === 'disponible')
    setData(filter)
  }

  const handleIndisponible = () => {
    const filter = dataSource.filter(item => item.status !== 'disponible')
    setData(filter)
  }

  const handleAll = () => {
    setData(dataSource)
  }

  useEffect(() => {
    setLoading(true)
    handleData()
  }, [])



  return (
    <div className="row">
      <div className="col-xl-8">
        <div className="card">
          <div className="card-header border-0 pb-0">
            <Header handleAll={handleAll} handleIndisponible={handleIndisponible} handleDisponible={handleDisponible}/>
            {/* <h4 className="fs-20">Calendrier</h4> */}
          </div>
          <div className="card-body border-0 pb-0">
            <List
              data={data}
              loading={loading}
            // sort={10}
            />
          </div>

        </div>
      </div>
      <div className="col-xl-4">
        <div className="card">
          <div className="card-header border-0 pb-0">
            {/* <h4 className="fs-20">Calendrier</h4> */}
          </div>
          <CalendarComponent onChangeDate={onChangeDate} />
        </div>
      </div>
    </div>
  )
}

export default Calendar