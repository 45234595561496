import icon_bed from '../../../images/documentation/chambres/icon_bed.png';
import icon_bed2 from '../../../images/documentation/chambres/icon_bed2.png';

export const ChambreIcon = [
    {
        title : "Icones des équipements",
        paragraph : ` Dans le champ Icone, vous pouvez mettre le nom de l'icone que vous voulez afficher sur la chambre.
            
        vous devez taper 'fa fa-' puis le nom de l'icone que vous voulez afficher( en anglais ).
        Exemple : 'fa fa-bed' pour afficher l'icone du lit.
        
        Pour voir la liste des icones disponibles, vous pouvez visiter le site : https://fontawesome.com/icons

        Voici en exemple conctrèt : fa fa-bed
        `,
        illustration : icon_bed,
    },
    {
        title : null,
        paragraph: null,
        illustration : icon_bed2,
    }
]