import React, { useEffect, useState } from "react";
import { getStatDay } from "../../../services/dashbordService";

function RemplissageJour() {

    const [data, setData] = useState({
        rempli: 0,
        tauxRemplissage: 0,
        nonRempli: 0,
        tauxNonRemplissage: 0,
    })

    useEffect(() => {
        getStatDay().then((response) => {
            setData(response.data);
        });
    }, []);

    return (
        <>
            <div className="col-xl-6 col-sm-6">
                <div className="card bg-secondary">
                    <div className="card-body">
                        <div className="d-flex align-items-end pb-4 justify-content-between">
                            <span className="fs-14 font-w500 text-white">Chambre libre aujourd'hui</span>
                            <span className="fs-20 font-w600 text-white"><span className="pe-2"></span>{data.nonRempli}</span>
                        </div>
                        <div className="progress default-progress h-auto">
                            <div className="progress-bar bg-white progress-animated" style={{ width: data.tauxNonRemplissage, height: "13px" }}>
                                <span className="sr-only">{data.tauxNonRemplissage} Complete</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-xl-6 col-sm-6">
                <div className="card bg-secondary">
                    <div className="card-body">
                        <div className="d-flex align-items-end pb-4 justify-content-between">
                            <span className="fs-14 font-w500 text-white">Chambre Occupée aujourd'hui</span>
                            <span className="fs-20 font-w600 text-white"><span className="pe-2"></span>{data.rempli}</span>
                        </div>
                        <div className="progress default-progress h-auto">
                            <div className="progress-bar bg-white progress-animated" style={{ width: data.tauxRemplissage, height: "13px" }}>
                                <span className="sr-only">{data.tauxRemplissage} Complete</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default RemplissageJour;