import React from 'react'
import { Card } from 'react-bootstrap'

function Calls({ client }) {
  return (
    <Card className='w-100'>
      <Card.Body>
        <h4 className='mb-4'>Appels</h4>
        En cours de travail...
      </Card.Body>
    </Card>
  )
}

export default Calls