import { Container } from "react-bootstrap";
import { useState,useEffect } from "react";

// componnents
import BreadCrumb from "../../components/Documentation/BreadCrumb";
import ArticleContent from "../../components/Documentation/ArticleContent";


function GuidesPage() {

    const [guide, setGuide] = useState(null);

    const complementPath = 'Guide';
    let partialPath = window.location.pathname.slice(15);


    useEffect(() => {
        const loadGuideData = async () => {

          try {
            // peut être un possible cause d'erreur si on fait n'importe quoi 
            if(partialPath.indexOf('/') === -1) {
                partialPath = partialPath+'/'+partialPath;
            }

            const data = await import('../../../data/guide/' + partialPath + complementPath);
            const { guideData } = data;

            setGuide(guideData[0]);

          } catch (error) {

            console.error('Erreur d\'importation doc (GuidesPages):', error);
          }
        };
    
        loadGuideData();
      }, []);

    return(
        <div>
           <BreadCrumb path_segment={partialPath} />
            <div className="card w-100 py-5 px-4">
                <Container className="mt-0">
                    { 
                        guide && (
                            <>
                                {   guide.mainTitle && (<h2>{ guide.mainTitle}</h2>)    }

                                {   guide.description && (<h6>{ guide.description }</h6>)   }
                                
                                {
                                   guide.article &&(
                                        guide.article.map( (guide, index) => (
                                            <ArticleContent key={index}
                                                title={guide.title}
                                                paragraph={guide.paragraph}
                                                src={guide.illustration}
                                            />
                                        ))
                                   )
                                }
                            </>
                        )
                    }
                </Container> 
            </div>
        </div>
    );
}

export default GuidesPage;