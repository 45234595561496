import React from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { createEquipment } from "../../../services/ChambreService";
import { validateEquipmentForm } from "../../../helpers/chambres";
import Tips from "./Tips";

const defaultState = {
  name: "",
  title: "",
  icon: "",
  description: "",
  image: null,
  id_chambre: "",
};

const ToastOption = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
};

function AddEquipment({ open, handleClose, id_chambre, handleEquipment }) {
  const [state, setState] = React.useState({
    ...defaultState,
    id_chambre,
  });
  const [loading, setLoading] = React.useState(false);

  const notifyError = (message) => toast.error(message, ToastOption);

  const handleState = (name, value) =>
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));

  const handleFile = (e) => {
    setState((prevState) => ({
      ...prevState,
      image: e.target.files[0],
    }));
  };

  const save = () => {
    if (validateEquipmentForm(state)) {
      setLoading(true);
      createEquipment(state)
        .then(({ data }) => {
          toast.success(`Équipement enregistré`, ToastOption);
          setState({
            ...defaultState,
            id_chambre,
          });
          handleEquipment();
          handleClose();
        })
        .catch((err) => {
          notifyError(`Erreur pendant l'enregistrement`);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      notifyError(`Veuillez remplir les champs obligatoires [nom, titre] `);
    }
  };

  return (
    <Modal
      show={open}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Ajouter un équipement
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 className="mb-4">Remplissez le formulaire</h4>
        <div>
          <div className="form-group mb-3">
            <label>Nom*</label>
            <input
              type="text"
              className="form-control input-default mb-2"
              placeholder="Nom"
              value={state.name}
              onChange={(e) => handleState("name", e.target.value)}
            />
          </div>
          <div className="form-group mb-3">
            <label>Titre*</label>
            <input
              type="text"
              className="form-control input-default mb-2"
              placeholder="Titre"
              value={state.title}
              onChange={(e) => handleState("title", e.target.value)}
            />
          </div>
          <div className="form-group mb-3">
            <label>Icone</label>
            <input
              type="text"
              className="form-control input-default mb-2"
              placeholder="Exemple: fa fa-bell"
              value={state.icon}
              onChange={(e) => handleState("icon", e.target.value)}
            />
            <small className="text-muted">
              Trouver la liste complete des icones disponible{" "}
              <a
                href="https://fontawesome.com/v5/search?o=r&m=free"
                target="_blank"
                className="text-secondary"
              >
                <strong>ici</strong>
              </a>
              <Tips />
            </small>
          </div>
          <div className="mb-3">
            <label htmlFor="formFile" className="form-label">
              Choisir une image
            </label>
            <input
              className="form-control"
              type="file"
              accept="image/*"
              onChange={handleFile}
            />
          </div>
          <div className="form-group mb-3">
            <label>Description</label>
            <textarea
              className="form-control"
              rows="4"
              onChange={(e) => handleState("description", e.target.value)}
              defaultValue={state.description}
            ></textarea>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-dark" onClick={handleClose}>
          Fermer
        </Button>
        <Button variant="primary" onClick={save} disabled={loading}>
          Ajouter
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddEquipment;
