import React, { useState, useEffect } from "react";
import logo from "../../../images/logo.png";
import { validateForm } from "../../../helpers/wifi";
import { loginWifi } from "../../../services/wifiService";
import { notifyError, notifySuccess } from "../../../helpers/notification";
import { ToastContainer } from "react-toastify";

const datetimenow = new Date().toISOString().slice(0, 16);
const datetimenow15 = new Date(new Date().getTime() + 15 * 60000).toISOString().slice(0, 16);

const defaultData = {
    email: "",
    password: "",
}

export default function LoginWifiUser()
{
    const [data, setData] = useState(defaultData);
    const [loading, setLoading] = useState(false);

    const handleData = (name, value) =>
    setData((prevState) => ({
        ...prevState,
        [name]: value,
    }));

    const resetData = () => {
        setData(defaultData);
      };
    
    const login = () => {
        if(!validateForm(data)){
            notifyError("Veuillez remplir tous les champs obligatoires");
            return;
        } 
        else {
            setLoading(true);
            loginWifi(data)
            .then((response) => {
                if(response.status === 202)
                {
                    notifySuccess(response.data.message);
                    resetData();
                    // reload 
                    window.location.reload();    
                }
                else
                {
                    notifyError(response.data.message);
                    console.log(response.data);
                }
            })
            .catch((error) => {
                notifyError("Erreur : " + error);
            })
            .finally(() => {
                setLoading(false);
            });

            
        }

    };  
    
    
    return(
        <>
            <ToastContainer />
            <div className="row mt-5">
                <div className="col-12 justify-content-center">
                    <img
                        src={logo}
                        alt=""
                        className="img-fluid d-block mx-auto"
                        width="20"
                    />

                    <h1 className="text-center">Stephen Hotel</h1>
                </div>
            </div>

            <div className="row justify-content-center align-items-center">
                <div className="card col-xl-6 col-lg-6 col-md-10 col-sm-10">
                    <div className="card-header">
                        <h5 className="card-title">Connectez vous à votre compte wifi</h5>
                    </div>
                    <div className="card-body">
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label htmlFor="firstname">Email</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    id="email"
                                    placeholder="Email"
                                    value={data.email}
                                    onChange={(e) => handleData("email", e.target.value)}
                                />
                            </div>
                            <div className="form-group col-md-12">
                                <label htmlFor="password">Mot de passe</label>
                                <input
                                    type="password"
                                    className="form-control"
                                    id="password"
                                    placeholder="Nom"
                                    value={data.password}
                                    onChange={(e) => handleData("password", e.target.value)}
                                />
                            </div>
                        </div>
                        
                    
                        <button
                            type="button"
                            className="btn btn-secondary btn-block mt-4"
                            onClick={login}
                            disabled={loading}
                        >
                            {loading ? (
                                <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                            ) : (
                                <span> Se connecter</span>
                            )}
                        </button>
                    </div>
                </div>
            </div>

        </>
        
    )
}