import List from "../components/factures/List";
import Header from "../components/factures/Headers";
import "bootstrap-daterangepicker/daterangepicker.css";
import { getListFacture, getFacturesCount } from "../../services/Facture";
import { getPaginationData } from "../../services/PaginationService";
import React, { useState, useRef, useEffect } from "react";
import CommingSoon from "../components/CommingSoon";

const Facture = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [facture_counts, setFactureCounts] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const sort = 10;

  // state pour la table et la pagination
  const [dataTable, setDataTable] = useState(data.slice(0, sort));
  const [isChangeDataTable, setIsChangeDataTable] = useState({});

  useEffect(() => {
    setDataTable(data.slice(0, sort));
  }, [data]);

  useEffect(() => {
    setDataTable(data.slice(isChangeDataTable.frist, isChangeDataTable.sec));
  }, [isChangeDataTable]);

  useEffect(() => {
    setLoading(true);
    getListFacture()
      .then((response) => {
        setData(response.data);
      })
      .finally(() => {
        setLoading(false);
      });

    getFacturesCount()
      .then((response) => {
        setFactureCounts(response.data.count);
      }
    )

  }, []);

  // pagination
  const paginate = (page_number) => {
    setLoading(true);
    getPaginationData("facture", page_number)
      .then(({ data }) => { 
          setData(data);
          setCurrentPage(page_number);
      }
    )
    .finally(() => setLoading(false));
  };


  const chackbox = document.querySelectorAll(".sorting_1 input");
  const motherChackBox = document.querySelector(".sorting_asc input");
  const chackboxFun = (type) => {
    for (let i = 0; i < chackbox.length; i++) {
      const element = chackbox[i];
      if (type === "all") {
        if (motherChackBox.checked) {
          element.checked = true;
        } else {
          element.checked = false;
        }
      } else {
        if (!element.checked) {
          motherChackBox.checked = false;
          break;
        } else {
          motherChackBox.checked = true;
        }
      }
    }
  };

  return (
    <>
      {/* <CommingSoon /> */}
      <Header />
      <div className="row mt-4">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body p-0">
              <List
                chackboxFun={chackboxFun}
                data={dataTable}
                loading={loading}
                sort={sort}
                facture_counts={facture_counts}
                currentPage={currentPage}
                paginate={paginate}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Facture;
