import React, { useEffect, useState } from "react";
import { Button, Nav } from "react-bootstrap";
import moment from "moment";
import "moment/locale/fr";
import DateRangePicker from "react-bootstrap-daterangepicker";
import CheckPermission from "../../../templates/components/CheckPermission/CheckPermission";

moment().locale("fr");

const TABS = [
  { label: "Tout", value: null },
  { label: "En attente", value: "pending" },
  { label: "Reservé", value: "booked" },
  { label: "Annulé", value: "canceled" },
  { label: "Remboursé", value: "refund" },
];

function Header({ handleData, handleOpen }) {
  const [state, setState] = useState({
    start: moment().subtract(29, "days"),
    end: moment(),
  });
  const [status, setStatus] = useState(null);
  const { start, end } = state;
  const handleCallback = (start, end) => {
    setState({ start, end });
    handleData(status, start, end);
  };
  const label =
    start.format("MMMM D, YYYY") + " - " + end.format("MMMM D, YYYY");

  const handleActive = (status = "1") => {
    handleData(status, start, end);
  };

  useEffect(() => {
    handleActive(status);
  }, [status]);

  return (
    <div className="d-flex justify-content-between align-items-center flex-wrap">
      <div className="card-action coin-tabs mb-2">
        <Nav as="ul" className="nav nav-tabs">
          {TABS.map((tab, index) => (
            <Nav.Item as="li" className="nav-item" key={index}>
              <Nav.Link
                className={`nav-link ${status === tab.value ? "active" : ""}`}
                onClick={() => setStatus(tab.value)}
              >
                {tab.label}
              </Nav.Link>
            </Nav.Item>
          ))}
        </Nav>
      </div>
      <div className="d-flex align-items-center mb-2 flex-wrap">
        <div className="guest-calendar">
          <DateRangePicker
            initialSettings={{
              startDate: start.toDate(),
              endDate: end.toDate(),
              ranges: {
                "Aujourd'hui": [moment().toDate(), moment().toDate()],
                Hier: [
                  moment().subtract(1, "days").toDate(),
                  moment().subtract(1, "days").toDate(),
                ],
                "7 derniers jours": [
                  moment().subtract(6, "days").toDate(),
                  moment().toDate(),
                ],
                "30 derniers jours": [
                  moment().subtract(29, "days").toDate(),
                  moment().toDate(),
                ],
                "Ce mois": [
                  moment().startOf("month").toDate(),
                  moment().endOf("month").toDate(),
                ],
                "Le mois dernier": [
                  moment().subtract(1, "month").startOf("month").toDate(),
                  moment().subtract(1, "month").endOf("month").toDate(),
                ],
              },
            }}
            onCallback={handleCallback}
          >
            <div
              id="reportrange"
              className="pull-right reportrange"
              style={{
                width: "100%",
              }}
            >
              {/* <i className="fa fa-calendar"></i>&nbsp;&nbsp; */}
              <span>{label}</span> <i className="fas fa-chevron-down ms-3"></i>
            </div>
          </DateRangePicker>
        </div>
        <CheckPermission permission="create_client">
          <Button
            className="ms-3"
            variant="outline-secondary"
            onClick={handleOpen}
          >
            <i className="fa fa-plus"></i>
          </Button>
        </CheckPermission>
      </div>
    </div>
  );
}

export default Header;
