import { Nav } from "react-bootstrap";
import List from "../components/Chambres/List";
import AddChambre from "../components/Chambres/Add";
import React, { useState, useRef, useEffect } from "react";
import { getChambres, getNextChambres, getPrevChambres, getChambresCount } from "../../services/ChambreService";
import Pagination from "../components/Chambres/Pagination";
import CheckPermission from "../../templates/components/CheckPermission/CheckPermission";

const TABS = [
  { label: "Tout", value: null },
  { label: "Disponibles", value: "disponible" },
  { label: "Réservées", value: "non disponible" },
];

const Chambres = () => {
  const [datas, setDatas] = useState([]);
  const [dataFilter, setDataFilter] = useState([]);
  const [loading, setLoading] = useState([]);
  const [status, setStatus] = useState(null);
  const [end_point, setEndPoint] = useState(0);
  const [start_point, setStartPoint] = useState(0);
  const [chambres_counts, setChambresCounts] = useState(0); 
  const [currentPage, setCurrentPage] = useState(1);

  const sort = 10;
  const activePag = useRef(0);

  const chageData = (frist, sec) => {};

  // state pour la table et la pagination
  const [isChangeDataTable, setIsChangeDataTable] = useState({});

  const nextChambres = (start_point, end_point) => {
    setLoading(true);
    getNextChambres(start_point, end_point)
      .then(({ data }) => {
        setDatas(data);
        dataFilter.length > 0 && setDataFilter(data);
        setStartPoint(data[0]?.id);
        setEndPoint(data[data.length - 1]?.id);
        setCurrentPage(currentPage + 1);
      })
      .finally(() => setLoading(false));
  };

  const prevChambres = (start_point, end_point) => {
    setLoading(true);
    getPrevChambres(start_point, end_point)
      .then(({ data }) => {
        // removing the first element
        data.shift();
        // reversing the array
        data.reverse();
        setDatas(data);
        setDataFilter(data);
        setStartPoint(data[0]?.id);
        setEndPoint(data[data.length - 1]?.id);
        setCurrentPage(currentPage - 1);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    // getting chmabre counts
    getChambresCount()
      .then((response ) => {
        setChambresCounts(response.data.count);
      } 
    )
    
  }, []);

  useEffect(() => {
    setLoading(true);
    getChambres()
      .then(({ data }) => {
        if (data.length) {
          setDatas(data);
          setDataFilter(data);
          setStartPoint(data[0]?.id);
          setEndPoint(data[data.length - 1]?.id);
        } else {
          setDatas([]);
          setDataFilter([]);
        }
      })
      .finally(() => setLoading(false));
  }, []);


  // use effect end and  start point
  useEffect(() => {
    console.log("start_point", start_point, "end_point", end_point);
  }, [start_point, end_point]);
  
  useEffect(() => {
    console.log('datas', datas);
  }, [datas]);

  useEffect(() => {
    if (status === null) {
      setDataFilter(datas);
    } else {
      setDataFilter(datas.filter((chambre) => chambre.status === status));
    }
  }, [status]);

  useEffect(() => {
    setDataFilter(
      datas
        .filter((chambre) =>
          chambre.status === status ? chambre.status : chambre
        )
        .slice(isChangeDataTable.frist, isChangeDataTable.sec)
    );
  }, [isChangeDataTable]);

  activePag.current === 0 && chageData(0, sort);
  
  return (
    <>
      <div className="mt-4 d-flex justify-content-between align-items-center flex-wrap">
        <div className="card-action coin-tabs mb-2">
          <Nav as="ul" className="nav nav-tabs">
            {TABS.map((tab, index) => (
              <Nav.Item as="li" className="nav-item" key={index}>
                <Nav.Link
                  className={`nav-link ${status === tab.value ? "active" : ""}`}
                  onClick={() => setStatus(tab.value)}
                >
                  {tab.label}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </div>
        <CheckPermission permission="create_room">
          <div className="d-flex align-items-center mb-2">
            <AddChambre />
          </div>
        </CheckPermission>
      </div>
      <div className="row mt-4">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body p-0">
              <div className="table-responsive">
                <div id="room_wrapper" className="dataTables_wrapper no-footer">
                  <List datas={dataFilter} loading={loading} />
                  {dataFilter.length > 0 && (
                    <Pagination
                      data={dataFilter}
                      sort={sort}
                      nextChambres = {nextChambres}
                      prevChambres = {prevChambres}
                      end_point = {end_point}
                      start_point = {start_point}
                      chambres_counts = {chambres_counts}
                      currentPage = {currentPage}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Chambres;
