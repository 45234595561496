import React from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import DeleteAccount from "./Delete";
import Edit from "./Edit";
import Loading from "../Loading";
import { getRole } from "../../../helpers/users";
import CheckPermission from "../../../templates/components/CheckPermission/CheckPermission";

function BrowseAccount({ loading, users, handleUsers }) {
  const [user, setUser] = React.useState(null);
  const [openEdit, setOpenEdit] = React.useState(false);

  const handleUser = (user) => {
    const role = getRole(user);
    setUser({
      id: user.id,
      name: user.name,
      email: user.email,
      role: role ? role.id : null,
    });
  };

  const openEditModal = (user) => {
    setOpenEdit(true);
    handleUser(user);
  };
  const closeEditModal = () => setOpenEdit(false);

  return (
    <>
      <Edit
        user={user}
        show={openEdit}
        handeClose={closeEditModal}
        handleUsers={handleUsers}
      />
      <Table responsive>
        <thead>
          <tr>
            <th>
              <strong>ID</strong>
            </th>
            <th>
              <strong>Nom</strong>
            </th>
            <th>
              <strong>Email</strong>
            </th>
            <th>
              <strong>Rôle</strong>
            </th>
            <th>
              <strong>Actions</strong>
            </th>
          </tr>
        </thead>
        <tbody>
          {users.map((user, index) => (
            <tr key={index}>
              <td>
                <strong>#{user.id}</strong>
              </td>
              <td>
                <div className="d-flex align-items-center">
                  <span className="w-space-no">{user.name}</span>
                </div>
              </td>
              <td>{user.email}</td>
              <td>
                <strong>{getRole(user) ? getRole(user).name : "Aucun"}</strong>
              </td>
              <td>
                <div className="d-flex">
                  <CheckPermission permission="edit_user">
                    <span
                      className="btn btn-primary shadow btn-xs sharp me-1"
                      onClick={() => openEditModal(user)}
                    >
                      <i className="fas fa-pencil-alt"></i>
                    </span>
                  </CheckPermission>
                  <CheckPermission permission="delete_user">
                  <DeleteAccount user={user} handleUsers={handleUsers} />
                  </CheckPermission>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {loading ? <Loading color="var(--secondary)" /> : <></>}
    </>
  );
}

export default BrowseAccount;
