import modifyClient1 from '../../../images/documentation/clients/modify_client_1.png';

export const ClientModificationGuide = [
    {
        title: "Etape 1 : Aller à l'onglet Clients",
        paragraph: "L'onglet Clients se trouve dans le menu de navigation à gauche de l'écran.",
        illustration : null,
    },
    {
        title : "Etape 2 : Choisir le client à modifier",
        paragraph: "Le client à modifier se trouve dans la liste des clients.",
        illustration : null,
    },
    {
        title: "Etape 3 : Cliquer sur le bouton d'option, puis modifier Modifier",
        paragraph: "Ce button se trouve en haut à droite du fiche information du client.",
        illustration : modifyClient1,
    },
    {
        title: "Etape 3 : Remplir les informations du client",
        paragraph: `Remplir les informations du client dans le formulaire.

                    Les informations du client sont :
                        nom, prenom, date de naisssance, lieu de naissance, adresse,
                        profession, email, numéro de téléphone, nationalité, nature pièce d'identité (passeport ou CIN ou permis),
                        numéro pièce d'identité, date de délivrance pièce d'identité, venant de, allant de, Date d'expiration‌ et la photo.

                    NB : Toutes les informations sont obligatoires sauf la photo.

        `,
        illustration : null,
    },
    {
        title: "Etape 4 : Cliquer sur le bouton Modifier",
        paragraph: "Le bouton Modifier se trouve en bas à droite du formulaire. Une fois cliqué, le client est modifié.",
        illustration : null,
    }
]

