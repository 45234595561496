import React, { useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { Col, Dropdown, Row } from "react-bootstrap";
import SectionWifi from "../wifi/user/SectionWifi";

//images
import pic_default from "./../../../images/avatar.svg";
import hotel1 from "./../../../images/gallery/hotel1.jpg";
import hotel2 from "./../../../images/gallery/hotel2.jpg";

import {
  DetailActions,
  ReservationActions,
} from "../../components/Clients/Actions";

import {
  getClient,
  getClientReservation,
  archiveClient,
} from "../../../services/ClientService";

import Loading from "../../components/Loading";
import Edit from "../../components/Clients/Edit";
import RoomSlider from "../../components/Chambres/RoomSlider";
import { asset_img } from "../../../helpers/data";
import OrderHistory from "../../components/Chambres/OrderHistory";
import Sessions from "../../components/Clients/Sessions";
import Calls from "../../components/Clients/Calls";
import { formatDate } from "../../../helpers/dates";
// import HistoriqueWifi from "../../components/Clients/HistoriqueWifi";
// import { getHistoriqueWifi } from "../../../services/wifiService";

const DetailsClient = () => {
  const [data, setData] = useState(null);
  const [orders, setOrders] = useState([]);
  // const [dataHisto, setDataHisto] = useState([]);
  // const [loadignHisto, setLoadingHisto] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingOrders, setLoadingOrders] = useState(true);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [selectBtn, setSelectBtn] = useState("Nouveau");

  const params = useParams();
  const id = params.id;

  const history = useHistory();

  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);

  // const handleDataHisto = () => {
  //   getHistoriqueWifi(id)
  //     .then(({ data }) => {
  //       setDataHisto(data);
  //     })
  //     .finally(() => setLoadingHisto(false));
  // }

  const handleData = () =>
    getClient(id)
      .then(({ data }) => {
        setData(data);
      })
      .finally(() => setLoading(false));

  const handleOrders = () => {
    getClientReservation(id)
      .then(({ data }) => {
        if (data && data.reservations) {
          setOrders(data.reservations);
        }
      })
      .finally(() => setLoadingOrders(false));
  };

  const handleArchive = () => {
    setLoading(true);
    archiveClient(id)
      .then( data => {
        if(data.status === 200) {
          setTimeout(
            () => {
              setLoading(false);
              history.push('/clients');
            }, 2000);
        }
      })
  }

  React.useEffect(() => {
    handleData();
    // setLoadingHisto(true);
    // handleDataHisto();
    handleOrders();
  }, [id]);

  return (
    <>
      <div className="row mt-4">
        <div className="col-xl-12">
          {loading ? (
            <Loading color="var(--secondary)" />
          ) : (
            <div className="row">
              <Edit
                client={data}
                handleClose={handleCloseEdit}
                handleData={handleData}
                open={openEdit}
              />
              <div className="col-xl-12">
                <div className="card overflow-hidden">
                  <div className="row m-0">
                    <div className="col-xl-6 p-0">
                      <div className="card-body">
                        <div className="guest-profile">
                          <div className="d-flex">
                            <img
                              src={
                                data.photo ? asset_img(data.photo) : pic_default
                              }
                              alt=""
                              style={{
                                objectFit: "cover",
                                objectPosition: "center",
                              }}
                            />
                            <div>
                              <h2 className="font-w600">
                                {data.lastname} {data.firstname}
                              </h2>
                              <span className="text-secondary">
                                ID : {data.ref}
                              </span>
                              <span className="d-block mt-2 text-secondary">
                                Date de naissance : <strong>{data.birth_date }</strong>
                              </span>
                              <span className="d-block text-secondary">
                                Lieu de naissance : <strong>{data.birth_place }</strong>
                              </span>
                              <span className="d-block text-secondary">
                                Profession : <strong>{data.profession }</strong>
                              </span>
                              <span className="d-block text-secondary">
                                Adresse : <strong>{data.adresse}</strong>
                              </span>
                              <span className="d-block mt-2 text-secondary">
                                Nationalité : <strong>{data.nationality}</strong>
                              </span>
                              <span className="d-block text-secondary">
                                Pièce d'identité:
                                <br />
                                <strong>{data.nature_pid??"Aucune pièce d'identité enregistré"}</strong>
                                <br />
                                <strong>
                                  <strong>{data.numero_pid}</strong>
                                </strong>
                              </span>
                              <span className="d-block mt-2 text-secondary">
                                Delivré : <strong>{data.delivery_date_pid }</strong>
                              </span>
                              <span className="d-block mt-2 text-secondary">
                                Venant de : <strong>{data.from_pid }</strong>
                              </span>
                              <span className="d-block mt-2 text-secondary">
                                Allant de : <strong>{data.to_pid }</strong>
                              </span>
                              <span className="d-block mt-2 text-secondary">
                                Expire le :{" "}
                                <strong>
                                  {data.date_expiration_pid? formatDate(data.date_expiration_pid) : "Aucune date enregistrée"}
                                </strong>
                              </span>
                              <div className="call d-flex align-items-center">
                                <a
                                  href={
                                    data.telephone
                                      ? `tel:${data.telephone}`
                                      : `#`
                                  }
                                >
                                  <i className="fas fa-phone-alt text-secondary"></i>
                                </a>
                                <button
                                  className="btn btn-secondary ms-3"
                                  onClick={() =>
                                    window.open(
                                      `mailto:${data.email}`,
                                      "_blank"
                                    )
                                  }
                                >
                                  <svg
                                    className="me-2"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24.18"
                                    viewBox="0 0 24 24.18"
                                  >
                                    <g
                                      id="_032-speech-bubble"
                                      data-name="032-speech-bubble"
                                      transform="translate(-1.63 0)"
                                    >
                                      <g
                                        id="Group_9"
                                        data-name="Group 9"
                                        transform="translate(1.63 0)"
                                      >
                                        <path
                                          id="Path_118"
                                          data-name="Path 118"
                                          d="M22.193,3.6A12,12,0,0,0,1.636,12.361a11.434,11.434,0,0,0,.82,4.015,11.885,11.885,0,0,0,1.7,2.969l-.99,2.347a1.778,1.778,0,0,0,1.951,2.46l4.581-.792A12.013,12.013,0,0,0,22.193,3.6ZM12.749,16.8H9.61a.9.9,0,1,1,0-1.81h3.139a.911.911,0,0,1,.9.9A.893.893,0,0,1,12.749,16.8Zm4.892-3.676H9.61a.911.911,0,0,1-.9-.9.893.893,0,0,1,.9-.9h8.031a.9.9,0,1,1,0,1.81Zm0-3.7H9.61a.9.9,0,1,1,0-1.81h8.031a.911.911,0,0,1,.9.9A.93.93,0,0,1,17.641,9.421Z"
                                          transform="translate(-1.63 0)"
                                          fill="#fff"
                                        />
                                      </g>
                                    </g>
                                  </svg>
                                  Envoyer un mail
                                </button>
                              </div>
                            </div>
                            <DetailActions handleEdit={handleOpenEdit} handleArchive={handleArchive} />
                          </div>
                          {false ? (
                            <div className="d-flex">
                              <div className="mt-4 check-status">
                                <span className="d-block mb-2">Check In</span>
                                <span className="font-w500 fs-16">
                                  October 30th, 2022 | 08:23 AM
                                </span>
                              </div>
                              <div className="mt-4">
                                <span className="d-block mb-2">Check Out</span>
                                <span className="font-w500 fs-16">
                                  November 2th, 2022
                                </span>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                        {false ? (
                          <>
                            <div className="d-flex flex-wrap">
                              <div className="mt-4 check-status">
                                <span className="d-block mb-2">Room Info</span>
                                <h4 className="font-w500 fs-24">
                                  Deluxe Z - 002424
                                </h4>
                              </div>
                              <div className="mt-4 ms-3">
                                <span className="d-block mb-2 text-black">
                                  Price
                                </span>
                                <span className="font-w500 fs-24 text-black">
                                  $145
                                  <small className="fs-14 ms-2 text-secondary">
                                    /night
                                  </small>
                                </span>
                              </div>
                            </div>
                            <p className="mt-2">
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit, sed do eiusmod tempor incididunt ut labore
                              et dolore magna aliqua. Ut enim ad minim veniam,
                              quis nostrud exercitation ullamco laboris nisi ut
                              aliquip ex ea commodo consequat. Duis aute irure
                              dolor in reprehenderit in voluptate velit esse
                              cillum dolore eu fugiat nulla pariatur. Excepteur
                              sint occaecat cupidatat non proident, sunt in
                              culpa qui officia deserunt mollit anim id est
                              laborum
                            </p>
                            <div className="facilities">
                              <div className="mb-3 ">
                                <span className="d-block mb-3">Facilities</span>
                                <Link
                                  to={"#"}
                                  className="btn btn-secondary light btn-lg"
                                >
                                  <svg
                                    className="me-2"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="28"
                                    height="20"
                                    viewBox="0 0 28 20"
                                  >
                                    <g>
                                      <path
                                        d="M27,14V7a1,1,0,0,0-1-1H6A1,1,0,0,0,5,7v7a3,3,0,0,0-3,3v8a1,1,0,0,0,2,0V24H28v1a1,1,0,0,0,2,0V17A3,3,0,0,0,27,14ZM7,8H25v6H24V12a2,2,0,0,0-2-2H19a2,2,0,0,0-2,2v2H15V12a2,2,0,0,0-2-2H10a2,2,0,0,0-2,2v2H7Zm12,6V12h3v2Zm-9,0V12h3v2ZM4,17a1,1,0,0,1,1-1H27a1,1,0,0,1,1,1v5H4Z"
                                        transform="translate(-2 -6)"
                                        fill="#135846"
                                      />
                                    </g>
                                  </svg>
                                  3 Bed Space
                                </Link>
                                <Link
                                  to={"#"}
                                  className="btn btn-secondary light btn-lg"
                                >
                                  <svg
                                    className="me-2"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="23.512"
                                    viewBox="0 0 20 23.512"
                                  >
                                    <g
                                      id="_010-security"
                                      data-name="010-security"
                                      transform="translate(-310.326 -159.324)"
                                    >
                                      <path
                                        id="Path_1958"
                                        data-name="Path 1958"
                                        d="M330.326,165.226a2.952,2.952,0,0,0-1.71-2.8l-7.5-2.951a2.139,2.139,0,0,0-1.581,0l-7.5,2.951a2.951,2.951,0,0,0-1.709,2.8v5.318a10.445,10.445,0,0,0,4.372,8.772l5.142,3.372a.871.871,0,0,0,.971,0l5.143-3.372a10.448,10.448,0,0,0,4.372-8.772Zm-2,0a.591.591,0,0,0-.342-.561l-7.5-2.951a.432.432,0,0,0-.317,0l-7.5,2.951a.59.59,0,0,0-.341.561v5.318a7.985,7.985,0,0,0,3.343,6.707l4.657,3.054,4.656-3.054a7.986,7.986,0,0,0,3.344-6.707Zm-8.657,7.273,4.949-5.843a.9.9,0,0,1,1.415,0,1.338,1.338,0,0,1,0,1.67L320.376,175a.9.9,0,0,1-1.414,0l-2.829-3.338a1.337,1.337,0,0,1,0-1.669.9.9,0,0,1,1.414,0Z"
                                        transform="translate(0 0)"
                                        fill="#135846"
                                        fill-rule="evenodd"
                                      />
                                    </g>
                                  </svg>
                                  24 Hours Guard
                                </Link>
                                <Link
                                  to={"#"}
                                  className="btn btn-secondary light btn-lg"
                                >
                                  <svg
                                    className="me-2"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="15.75"
                                    viewBox="0 0 20 15.75"
                                  >
                                    <g
                                      id="internet"
                                      transform="translate(0 -2.15)"
                                    >
                                      <g id="Group_22" data-name="Group 22">
                                        <path
                                          id="Path_1969"
                                          data-name="Path 1969"
                                          d="M18.3,7.6a11.709,11.709,0,0,0-16.6,0,.967.967,0,0,1-1.4,0,.967.967,0,0,1,0-1.4,13.641,13.641,0,0,1,19.4,0,.99.99,0,0,1-1.4,1.4Z"
                                          fill="#135846"
                                        />
                                      </g>
                                      <g id="Group_23" data-name="Group 23">
                                        <path
                                          id="Path_1970"
                                          data-name="Path 1970"
                                          d="M15.4,10.4a7.667,7.667,0,0,0-10.7,0A.99.99,0,0,1,3.3,9,9.418,9.418,0,0,1,16.8,9a.99.99,0,0,1-1.4,1.4Z"
                                          fill="#135846"
                                        />
                                      </g>
                                      <g id="Group_24" data-name="Group 24">
                                        <path
                                          id="Path_1971"
                                          data-name="Path 1971"
                                          d="M12.6,13.4a3.383,3.383,0,0,0-4.9,0,.967.967,0,0,1-1.4,0,1.087,1.087,0,0,1,0-1.5,5.159,5.159,0,0,1,7.7,0,1.088,1.088,0,0,1,0,1.5A.967.967,0,0,1,12.6,13.4Z"
                                          fill="#135846"
                                        />
                                      </g>
                                      <circle
                                        id="Ellipse_10"
                                        data-name="Ellipse 10"
                                        cx="1.9"
                                        cy="1.9"
                                        r="1.9"
                                        transform="translate(8.2 14.1)"
                                        fill="#135846"
                                      />
                                    </g>
                                  </svg>
                                  Free Wifi
                                </Link>
                              </div>
                              <Link
                                to={"#"}
                                className="btn btn-secondary light"
                              >
                                2 Bathroom
                              </Link>
                              <Link
                                to={"#"}
                                className="btn btn-secondary light"
                              >
                                Air Conditioner
                              </Link>
                              <Link
                                to={"#"}
                                className="btn btn-secondary light"
                              >
                                Television
                              </Link>
                            </div>
                          </>
                        ) : (
                          <div className="text-muted text-center p-4">
                            Aucune reservation enregistrée
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-xl-6 p-0">
                      <RoomSlider chambre={null} equipements={[]} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-12">
                <OrderHistory datas={orders} loading={loadingOrders} />
              </div>

              
              {/* <div className="col-12">
                  { data !== null ? <SectionWifi data={ data} orders = { orders } handleHisto={handleDataHisto}/> : null }
              </div> */}
              {/* <div className="col-xl-12">
                <HistoriqueWifi  data={dataHisto} loadignHisto={loadignHisto} />
              </div> */}
              
              {/* <div className="col-xl-12">
                <Row>
                  <Col lg={7} md={12}>
                    <Sessions client={data.id} />
                  </Col>
                  <Col lg={5} md={12}>
                    <Calls client={data.id} />
                  </Col>
                </Row>
              </div> */}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default DetailsClient;
