import { status } from "../constants/reservations";

export const getStatus = (s) => {
  if (parseInt(s) === 0) {
    return status.pending;
  } else {
    return status.done;
  }
};

export const daydiff = (d1, d2) => {
  const date1 = new Date(d1);
  const date2 = new Date(d2);
  const diffTime = Math.abs(date2 - date1);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  return diffDays;
};
