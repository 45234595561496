import { createFormData } from "../helpers/services";
import axiosInstance from "./AxiosInstance";

export function getStatRemplissageDaily() {
  return axiosInstance.get(`stat/remplissage/daily`);
}

export function getStatRemplissageHebdomadire() {
    return axiosInstance.get('stat/remplissage/hebdomadaire');
}

export function getStatRemplissageMonthly() {
  return axiosInstance.get('stat/remplissage/monthly');
}

export function getStatRemplissageYearly() {
  return axiosInstance.get('stat/remplissage/yearly');
}

export function getStatDay() {
  return axiosInstance.get('stat/day');
}

export function getStatRevenueDaily() {
  return axiosInstance.get("stat/revenue/daily");
}
export function getStatRevenueHebd() {
  return axiosInstance.get("stat/revenue/hebd");
}
export function getStatRevenueMonthly() {
  return axiosInstance.get("stat/revenue/monthly");
}
export function getStatRevenueYearly() {
  return axiosInstance.get("stat/revenue/yearly");
}

